import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MFAData, OktaInfoModel } from 'src/app/shared/models/oktaInfoModel';
import { OktaResultModel } from 'src/app/shared/models/oktaResultModel';
import { BootstrapRequestContext } from 'tbs-typings';
import { SaveMFADataResultType, SaveResult } from '../../shared/models/saveResult.model';
import { LanguageSelectionData } from '../models';
import { HelperService } from './helper.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Constants } from 'src/app/shared/models/constants';

@Injectable({
  providedIn: 'root'
})
export class MFAService {
  apiBaseUrl: string;
  returnUrl: string;

  constructor(private readonly http: HttpClient, 
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly helperService: HelperService,
    private readonly authenticationService: AuthenticationService) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }

  public getMFAOptions(inLoginProcess = true, employeeRecordID = Constants.GuidEmpty) {
    let params = new HttpParams();
    params = params.append('inLoginProcess', inLoginProcess);
    params = params.append('employeeRecordID', employeeRecordID);
    const requestUrl = this.apiBaseUrl + 'okta/options';
    return this.http.post<SaveResult>(requestUrl,null, { params: params });
  }

  public sendMFACode(model: OktaInfoModel) {
    let requestUrl = this.apiBaseUrl + 'okta/sendcode';
    try {
      let curLanguageCode: LanguageSelectionData = this.helperService.getCurrentLanguageCode();
      requestUrl += "?culture=" + curLanguageCode.cultureCode;
    } catch { }
    return this.http.post<OktaResultModel>(requestUrl, model);
  }

  public verifyMFACode(model: OktaInfoModel) {
    let requestUrl = this.apiBaseUrl + 'okta/mfaverify';
    let params = new HttpParams();
    params = params.append('grant_type', 'MfaVerify');
    params = params.append('oktaInfoModel', JSON.stringify(model));
    return this.http
      .post<any>(requestUrl, params.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
  }
  public validateMfaUniqueIdentifier(mfaUniqueIdentifierIndex: string, inputValue: string) {
    let requestUrl = this.apiBaseUrl + 'okta/validateidentifier' + '/' + mfaUniqueIdentifierIndex + '/' + inputValue;
    return this.http.get<boolean>(requestUrl);
  }

  public saveMFAData(model: MFAData) {
    let requestUrl = this.apiBaseUrl + 'okta/savemfadata';
    let curLanguageCode: LanguageSelectionData = this.helperService.getCurrentLanguageCode();
    requestUrl += "?culture=" + curLanguageCode.cultureCode;
    return this.http.post<SaveMFADataResultType>(requestUrl, model);
  }


  public updateMFAData(model: MFAData) {
    const requestUrl = this.apiBaseUrl + 'okta/updatemfadata';
    return this.http.post<SaveResult>(requestUrl, model);
  }

  //getMFAData
  public getMFAData() {
    const requestUrl = this.apiBaseUrl + 'okta/getmfadata';
    return this.http.get<MFAData>(requestUrl);
  }
}

import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, BehaviorSubject, map } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class PreloadingModulesService implements PreloadingStrategy {
  private readonly loadedModulesPath: Array<string> = [];
  public modulesSpecialPermission = {
    articles: new BehaviorSubject(false),
    trs: new BehaviorSubject(false),
    claims: new BehaviorSubject(false),
    campaign: new BehaviorSubject(false)
  };
  constructor(private readonly authenticationService: AuthenticationService, 
    private readonly helperService: HelperService) { }
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    try {
      return route?.data?.preload && this.authenticationService.currentUser ? this.authenticationService.currentUser.pipe(() => this.isPreLoadApplicable ? this.checkSpecialPermissionAccessForTheModulesAndLoad(route, load) : of(null)) : of(null);
    } catch { }
    return of(null);
  }

  private get isPreLoadApplicable(): boolean {
    return this.helperService.IsVanityURLForMultiTenantEnabled() ? true : this.authenticationService.isLogin;
  }

  /**
   * To Check Special permission for the module wise
   * @param route 
   * @param load 
   * @returns 
   */
  private checkSpecialPermissionAccessForTheModulesAndLoad(route: Route, load: () => Observable<any>): Observable<any> {
    if (!this.loadedModulesPath.includes(route.path)) {
      switch (route.path) {
        case "article":
          return this.loadModulesWithSpecialPermissionCheck(this.modulesSpecialPermission.articles, route, load);
        case "trs":
          return this.loadModulesWithSpecialPermissionCheck(this.modulesSpecialPermission.trs, route, load);
        case "campaign":
          return this.loadModulesWithSpecialPermissionCheck(this.modulesSpecialPermission.campaign, route, load);
        case "claim":
        case "claim/:id":
          return this.loadModulesWithSpecialPermissionCheck(this.modulesSpecialPermission.claims, route, load);
      }
      return this.load(route, load);
    }
    return of(null);
  }

  /**
   * Load Module based on the loaded status check
   */
  private loadModulesWithSpecialPermissionCheck(moduleObserver: Observable<any>, route: Route, load: () => Observable<any>): Observable<any> {
    moduleObserver.subscribe();
    return moduleObserver.pipe(map(status => status ? this.load(route, load) : of(null)));
  }

  /**
   * Common method for returning that load observable
   * @param route 
   * @param load 
   * @returns 
   */
  private load(route: Route, load: () => Observable<any>): Observable<any> {
    this.loadedModulesPath.push(route.path);
    return load();
  }
}

import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil, of, catchError } from 'rxjs';
import { AgreementType, SaveAgreementRequestType } from 'src/app/shared/models/payment.model';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { SaveResult } from 'src/app/shared/models/saveResult.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-payment-agreement',
  templateUrl: './payment-agreement.component.html'
})
export class PaymentAgreementComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @Input('agreementType') agreementType: AgreementType;
  @Input('employeeBenefitIds') employeeBenefitIds: number[];

  saveError = false;
  myLoader = 'paymentAgreementLoader';

  constructor(
    private readonly injector: Injector,
    private readonly activeModal: NgbActiveModal,
    private readonly paymentService: PaymentService,
    private readonly ngxService: NgxUiLoaderService
  ) {
    super(injector);
    this.addResourceStringKeys(['Aon.Common.Error', 'Aon.Common.Back', 'Aon.Common.Confirm', 'Aon.Payment.AgreementAssent']);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.getResouceStringsAsObject(res);
      });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  onSuccess(): void {
    this.activeModal.close('success');
  }

  onClose(): void {
    this.activeModal.dismiss();
  }

  validate() {
    return !!this.agreementType?.assent;
  }

  confirm() {
    if (!this.validate()) return;
    this.saveAgreement();
  }

  private saveAgreement(): void {
    let data = {
      agreementRecordID: this.agreementType?.recordID,
      assent: this.agreementType?.assent,
      employeeBenefitIDs: this.employeeBenefitIds || []
    } as SaveAgreementRequestType;

    this.ngxService.start(this.myLoader);
    this.paymentService
      .saveAgreement(data)
      .pipe(
        takeUntil(this.unsubscribe),
        catchError((error, caught) =>
          of({
            didSave: false,
            errorMessages: [this.getString('Aon.Common.Error')],
            recordID: null,
            alertMessages: [],
            employeeBenefitId: -1,
            code: null,
            proceedCreateAccount: false
          } as SaveResult)
        )
      )
      .subscribe((sr) => {
        this.saveError = !sr.didSave;
        this.ngxService.stop(this.myLoader);
        if (sr.didSave) {
          this.activeModal.close('success');
        }
      });
  }
}

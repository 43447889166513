import { Component, Injector } from '@angular/core';
import { TBSBaseComponent } from '../../models/tbsBaseComponent';
import { Subject, takeUntil } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalObjectsService } from '../../services/global-objects.service';
import { SelfService } from '../../models/constants';

@Component({
  templateUrl: './self-service-manage-population-template-modal.component.html'
})
export class SelfServiceManagePopulationTemplateModalComponent extends TBSBaseComponent {
  roleCondition = "";
  isMultiSelectPopulation = true;
  selfServiceConstant = SelfService;
  headerLabel = "";
  onSubmitPopulation: Subject<{
    roleCondition: string,
    selectedPopulation?: string
  }> = new Subject();
  selectedPopulations: Array<string>;
  selectedPopulation: string;
  multiSelectOptions;
  public onSubmitErrorMessage: string = "";
  constructor(injector: Injector, public readonly globalObjectsService: GlobalObjectsService,
    private readonly activeModal: NgbActiveModal
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.SelfService.PopulationVisibility.HeaderLabel',
      'Aon.SelfService.PopulationVisibility.Controls.Label',
      'Aon.Validation.Required.ErrorMessage'
    ]);

  }

  ngOnInit(): void {
    this.multiSelectOptions = this.globalObjectsService?.Populations.map(r => r.name);

    if (this.isMultiSelectPopulation)
      this.selectedPopulations = this.helperService.roleConditionToPopulationsConversion(this.roleCondition);
    else if (!this.selectedPopulation)
      this.selectedPopulation = this.helperService.roleConditionToPopulationsConversion(this.roleCondition).length === this.multiSelectOptions.length ? 'All' : this.helperService.roleConditionToPopulationsConversion(this.roleCondition)[0];

    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));
  }

  OnSubmit(): void {
    this.onSubmitErrorMessage = "";
    if (this.isMultiSelectPopulation) {
      this.onSubmitPopulation.next({
        roleCondition: this.helperService.populationsToRoleConditionConversion(this.selectedPopulations),
        selectedPopulation: this.selectedPopulations.join('|')
      });
    }
    else {
      this.onSubmitPopulation.next({
        roleCondition: this.helperService.populationsToRoleConditionConversion(this.selectedPopulation),
        selectedPopulation: this.selectedPopulation
      });
    }
  }

  onPopulationChange() {
    this.onSubmitErrorMessage = "";
  }

  OnCancel(): void {
    this.onSubmitErrorMessage = "";
    this.activeModal.dismiss();
    this.selectedPopulations = this.helperService.roleConditionToPopulationsConversion(this.roleCondition);
  }
}
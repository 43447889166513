import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil, take } from 'rxjs/operators';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ErrorOptions } from '../../../shared/models/constants';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-error-activity',
  templateUrl: './error-activity.component.html'
})
export class ErrorActivityComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  activityData: any;
  nextOption: string;
  baseUrl: string;
  hasErrorOptions: boolean = false;
  hasExitOnBackOption: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly quoteService: QuoteService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.ErrorActivityOptions.AbortNewPurchase',
      'Aon.ErrorActivityOptions.ReplaceExistingPurchase',
      'Aon.ErrorActivityOptions.ResumeExistingPurchase',
      'Aon.ErrorActivityOptions.CancelPurchase'
    ]);

  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });

    this.baseUrl = this.helperService.getBaseUrl();
    if (this.activityData?.Response?.Options?.length > 0) {
      this.hasErrorOptions = true;
      this.hasExitOnBackOption = this.activityData.Response.Options.some(o => o === ErrorOptions.ExitOnBack);
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  getErrorText(): string {
    if (this.activityData.Error) {
      return this.activityData.Error;
    }
    return this.resourceStrings['Aon.Common.Error'];
  }

  selOption(option: string) {
    this.nextOption = option;
  }

  confirm() {
    switch (this.nextOption) {
      case ErrorOptions.AbortNewPurchase:
        {
          this.gotoHome();
          if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
          }
        }
        break;
      case ErrorOptions.ReplaceExistingPurchase:
        this.removePurchase();
        break;
      case ErrorOptions.ResumeExistingPurchase:
        this.resumeExistingPurchase();
        break;
      default:
        this.removePurchase();
        break;
    }
  }

  removePurchase() {
    if (this.activityData?.Response?.Data?.Previous_EmployeebenefitID != undefined && this.activityData?.Response?.Data?.Previous_EmployeebenefitID != null) {
      this.quoteService.removePurchase(this.activityData.Response.Data.Previous_EmployeebenefitID)
        .pipe(take(1))
        .subscribe(response => {
          if (response.didSave == true) {
            if (this.nextOption == ErrorOptions.ReplaceExistingPurchase) {
              this.callSableProcess();
            } else if (this.nextOption == ErrorOptions.Cancel) {
              this.gotoHome();
              if (this.modalService.hasOpenModals()) {
                this.modalService.dismissAll();
              }
            }
          }
        })
    }
  }

  gotoHome() {
    this.router.navigate([this.baseUrl + '/home']);
  }

  resumeExistingPurchase() {

  }

  callSableProcess() {

  }

}

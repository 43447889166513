<div AlertBannerCloseButtonFocus class="alert banner error" id="flow-error-message" role="alert">
  <div class="title">
    <div class="title-content">
      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
    </div>
    <i role="button" class="fal fa-times  focus-visibility" tabindex="0"
      (keydown.enter)="helperService.closeInfo('flow-error-message')"
      (click)="helperService.closeInfo('flow-error-message')"
      [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
  </div>
  <p [innerHTML]="getErrorText() | safeHtml"></p>
</div>
@if (hasErrorOptions) {
<div class="row">
  @if (!hasExitOnBackOption) {
  <div class="col-12 m-b-40">
    @for (option of this.activityData.Response.Options; track option) {
    <div class="rad-checkbox-array">
      <div class="aon-form-radio">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="errorOptions" (change)="selOption(option)" id="{{option}}">
          <label class="form-check-label" for="{{option}}">
            @switch (option) {
            @case ('ABORT_NEW_PURCHASE') {
            <span>{{resourceStrings['Aon.ErrorActivityOptions.AbortNewPurchase']}}</span>
            }
            @case ('REPLACE_EXISTING_PURCHASE') {
            <span>{{resourceStrings['Aon.ErrorActivityOptions.ReplaceExistingPurchase']}}</span>
            }
            @case ('RESUME_EXISTING_PURCHASE') {
            <span>{{resourceStrings['Aon.ErrorActivityOptions.ResumeExistingPurchase']}}</span>
            }
            @default {
            <span>{{resourceStrings['Aon.ErrorActivityOptions.CancelPurchase']}}</span>
            }
            }
          </label>
        </div>
      </div>
    </div>
    }
  </div>
  }
</div>
}
@if(hasErrorOptions){
<div class="button-sleeve">
  @if (!hasExitOnBackOption) {
  <button type="button" class="prominent" (click)="confirm()">
    {{resourceStrings['Aon.Common.Confirm']}}</button>
  }
  @if (hasExitOnBackOption) {
  <button class="ghost" (click)="gotoHome()">
    {{resourceStrings['Aon.Common.Back']}}</button>
  }
</div>
}
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        @if (headerLabel != '') {
        {{headerLabel}}
        } @else {
        {{resourceStrings['Aon.SelfService.PopulationVisibility.HeaderLabel']}}
        }
    </h4>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="OnCancel()">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="basic-card edit-state">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>{{resourceStrings['Aon.SelfService.PopulationVisibility.Controls.Label']}}</label>
                    @if ((isMultiSelectPopulation && selectedPopulations?.length == 0) || (!isMultiSelectPopulation &&
                    selectedPopulation?.length ==0)) { <div class="alert contextual error">
                        <div role="alert">
                            <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                            <span
                                [innerHTML]="resourceStrings['Aon.Validation.Required.ErrorMessage'] | textTransform : {fieldName:resourceStrings['Aon.SelfService.PopulationVisibility.Controls.Label']} | safeHtml"></span>
                        </div>
                    </div>
                    }
                    <div class="aon-form-input-wrapper select">
                        @if (isMultiSelectPopulation)
                        {
                        <aon-input-multiselect [ngClass]="{'ng-invalid ng-touched': selectedPopulations?.length == 0}"
                            [options]="multiSelectOptions" [(ngModel)]="selectedPopulations"
                            (ngModelChange)="onPopulationChange()" required></aon-input-multiselect>

                        }
                        @else
                        {
                        <select [(ngModel)]="selectedPopulation" (ngModelChange)="onPopulationChange()">
                            <option [value]="selfServiceConstant.PopulationAll" selected>
                                {{selfServiceConstant.PopulationAll}}</option>
                            @for (population of multiSelectOptions; track population)
                            {
                            <option [value]="population">
                                {{population}}
                            </option>
                            }
                        </select>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
@if (onSubmitErrorMessage != '') {
<div class="alert contextual error m-t-20">
    <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
    <span [innerHTML]="onSubmitErrorMessage | safeHtml"></span>
</div>
}
<div class="button-sleeve">
    <button type="button" class="prominent m-t-32" (click)="OnSubmit()"
        [innerHTML]="resourceStrings['Aon.Common.OK'] | safeHtml"></button>
    <button type="button" class="ghost m-t-32" (click)="OnCancel()"
        [innerHTML]="resourceStrings['Aon.Common.Cancel'] | safeHtml"></button>
</div>
import { Component, OnInit, Inject, Injector, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { CommonService } from 'src/app/core/services';
import { NgbDropdown, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelper } from 'src/app/core/guards/jwt.helper';
import { Router } from '@angular/router';
import { BootstrapRequestContext } from 'tbs-typings';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { take, takeUntil } from 'rxjs/operators';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { Constants, SelfService, SessionStorageKey } from 'src/app/shared/models/constants';
import { IEditableMBRSiteToolbar } from 'src/app/shared/editable-mbr-site-toolbar/editable-mbr-site-toolbar.model';
import { SelfServiceService } from '../self-service.service';
import { SelfServiceManagePopulationTemplateModalComponent } from 'src/app/shared/self-service-templates/self-service-manage-population-template-modal/self-service-manage-population-template-modal.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-self-service-masthead',
  templateUrl: './self-service-masthead.component.html'
})
export class SelfServiceMastheadComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  isNavbarCollapsed = true;
  preferredName: string;
  resourcestrings = {};
  headMenuObject: any;
  hideLanguageSelection: boolean = false;
  mobileMaxWidth = 576; //Max scree size for mobile
  isMobile = false;
  TID: string;
  isFailedPayment: boolean;
  uiConfig: UIConfigurationType;
  isFromMobileApp: boolean;
  hasGracePeriod: boolean;
  isImpersonation: string;
  dropDownAutoClose: boolean | string = true;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  modalOption: NgbModalOptions = {};
  siteTheme: string;
  isInternalEditorSite = false;
  toolbarControlsForMasthead: IEditableMBRSiteToolbar.Controls;
  public readonly ToolbarType = IEditableMBRSiteToolbar.ToolbarType;

  constructor(
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly injector: Injector,
    public authenticationService: AuthenticationService,
    public commonService: CommonService,
    private readonly modalService: NgbModal,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly selfServiceService: SelfServiceService,
    private readonly ngxService: NgxUiLoaderService,
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
    this.addResourceStringKeys(this.resService.mastheadStringKeys);
    this.addResourceStringKeys(this.resService.homeStringKeys);
    this.headMenuObject = JSON.parse(bootstrapRequestContext.mastheadMenu);
    this.isInternalEditorSite = this.helperService.isInternalEditorSite;
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
        this.loadToolBarControls(this.selfServiceService.selectedPopulationFromSessionCache);
      });
  }
  toggleNavBar() {
    this.helperService.narBarCollapseState.next(!this.isNavbarCollapsed)
  }

  ngOnInit(): void {
    let isCurrentUserSubscribed = false;
    this.globalObjectsService.checkHeaderFooterConfig.subscribe(value => {
      if (value) {
        this.helperService.narBarCollapseState.subscribe((isCollapsed) => {
          this.isNavbarCollapsed = isCollapsed;
        });
        this.uiConfig = this.uiconfigrationService.getUIConfiguration();
        this.siteTheme = this.uiConfig?.uiConfiguration?.presetTheme;
        this.isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';
        this.getCurrentPathRoleSettingStatus(this.injector);

        //currentUser does not unsubscribe as masthead component is always available and ngdestroyed not triggered 
        //because of this globalcartbenefits api called many times
        //So flag check for prevent current user subsription multiple times
        if (!isCurrentUserSubscribed) {
          this.authenticationService.currentUser
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((user) => {
              this.isAuthenticated = this.authenticationService.isLogin;
              isCurrentUserSubscribed = true;
              if (user?.id_token) {
                const jwtHelper = new JwtHelper();
                const decodedUser = jwtHelper.decodeToken(user.id_token);

                this.updateAnalyticsValues(decodedUser);
                this.globalObjectsService.reloadShoppingCart.next(true);
                this.globalObjectsService.reloadProfileGracePeriod.next(true);
              }
            });
        }
        this.globalObjectsService.compactEmployeeInformation
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(emp => {
            if (this.authenticationService.isLogin) {
              this.preferredName = emp?.preferredName;
            }
          });

        this.getScreenView();
      }
      if (this.helperService.isLoggedOn()) {
        const jwtHelper = new JwtHelper();
        const user = JSON.parse(sessionStorage.getItem('currentUser'));
        this.isImpersonation = jwtHelper.decodeToken(user.id_token).IsImpersonation;
      }

      if (this.authenticationService.isLogin) {
        this.globalObjectsService.compactEmployeeInformation.subscribe(data => {
          if (this.authenticationService.isLogin && data == null) {
            this.commonService.getCompactEmployeeInformation()
              .pipe(take(1))
              .subscribe(data => {
                this.globalObjectsService.compactEmployeeInformation.next(data);
              })
          }
        })
      }
    });

    this.commonService.resetLanguageList.subscribe(rl => {
      //show site whole list after logout
      if (rl
        && this.hideLanguageSelection
        && !this.helperService.isLoggedOn()
        && sessionStorage.getItem('AllowedLanguages')
        && JSON.parse(sessionStorage.getItem('AllowedLanguages')).allowedLanguages.length > 1
      ) {
        this.hideLanguageSelection = false;
      }
    });

    if (this.bootstrapRequestContext.enableArticlePreAuthentication && this.helperService.isLoggedOn()) {
      this.commonService.resetOpenAccessDetails.next(true);
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  getScreenView() {
    this.isMobile = window?.innerWidth < this.mobileMaxWidth;
  }

  logOut(): void {
    let urL = this.router.url.valueOf();
    if (!urL.includes(this.baseUrl + "/quote") || !this.uiConfig.uiConfiguration.showAlertPopup || (urL.includes("/complete"))) {
      this.authenticationService.logout().subscribe(() => {
        this.helperService.logoutRedirection();
      });
    } else if (this.uiConfig.uiConfiguration.showAlertPopup) {
      this.helperService.logoutRedirection();
    }
  }


  showAfterLoginCheck(): boolean {
    if (!this.helperService.AllowAnonymousAccess() && !this.authenticationService.isLogin) {
      return false;
    }
    return true;
  }

  onAllowedLanguagesLoaded(hideLanguageSelection: boolean): void {
    this.hideLanguageSelection = hideLanguageSelection;
  }

  closemodalcross(statusofclick: boolean) {
    this.isNavbarCollapsed = statusofclick;
  }

  truncate(input: string) {
    if (input && input.length > 10) {
      return input.substring(0, 10) + '...';
    }
    return input;
  }


  clickedDropDown: NgbDropdown;
  closeOtherDropdowns(clickedDropdown: NgbDropdown) {
    this.clickedDropDown = clickedDropdown;
    setTimeout(() => {
      let totalPlanCon = document.querySelectorAll<HTMLElement>(".qContent").length;
      for (let i = 0; i < totalPlanCon; i++) {
        let offsetHeightCn = document.querySelectorAll<HTMLElement>(".qContent")[i].offsetHeight;
        let scrollHeightCn = document.querySelectorAll<HTMLElement>(".qContent")[i].scrollHeight;
        if (scrollHeightCn == offsetHeightCn) {
          document.querySelectorAll<HTMLElement>('.qContent')[i].classList.add("disableTooltip");
        }

      }
    }, 100);

    this.dropdowns.toArray().forEach(el => {
      if (el != clickedDropdown)
        el.close();
    });

    this.keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown);
  }

  /**
   * To handle the custom browser scroll event.
   * Nav drop down getting close when the user clicks on the scroll bar
   * So, Adding some custom events to track the movement and making it open
   */
  keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown: NgbDropdown): void {
    let scrollEventFired: boolean = false;
    try {
      const self = this;
      const eventHandlers = {
        scroll() {
          self.dropDownAutoClose = false;
          scrollEventFired = true;
        },
        mousedown() {
          // 3 Step
          document.addEventListener("scroll", eventHandlers.scroll);
          self.dropDownAutoClose = true;
          scrollEventFired = false;
        },
        mouseup() {
          self.dropDownAutoClose = true;
          if (!scrollEventFired) {
            eventHandlers._clear();
            setTimeout(() => {
              if (clickedDropdown.isOpen()) {
                self.keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown);
              }
            }, 10);
          }
        },
        _clear() {
          document.removeEventListener("mousedown", eventHandlers.mousedown);
          document.removeEventListener("scroll", eventHandlers.scroll);
          document.removeEventListener("mouseup", eventHandlers.mouseup);
        }
      }

      // 1 Step
      document.addEventListener("mousedown", eventHandlers.mousedown);
      // 2 Step
      document.addEventListener("mouseup", eventHandlers.mouseup);
    } catch { }
  }

  ifLogoutUser(decodedUser: any) {
    let isLogoutUser = !this.authenticationService.isLogin && this.analyticsService.verifyDataLayerValue('IsAuthenticated', 'true');

    if (isLogoutUser) {
      this.TID = this.analyticsService.getDataLayerValue('TID');
    } else {
      this.TID = decodedUser.TID;
    }
  }

  updateAnalyticsValues(decodedUser: any) {

    this.ifLogoutUser(decodedUser);

    if (this.TID == Constants.GuidEmpty && this.helperService.getUrlParameter('TID')) {
      this.TID = this.helperService.getUrlParameter('TID');
    }

    let externalTID = this.helperService.getUrlParameter('external_TID') ?? Constants.GuidEmpty;

    let user_info_params = {
      "event": "userinfo-access",
      "IsAuthenticated": this.TID && this.TID != Constants.GuidEmpty,
      "PreferredCulture": decodedUser.PreferredCulture,
      "ClientCode": decodedUser.ClientCode,
    };

    if (decodedUser.IsSso && decodedUser.IsSso != "False") {
      this.analyticsService.updateDataLayer("IsSSO", decodedUser.IsSso);
    }

    let user_additional_info = {};

    user_info_params = {
      ...user_info_params,
    }

    if (this.TID != Constants.GuidEmpty) {
      user_additional_info = {
        "TID": this.TID,
        "externalTID": externalTID,
        "IsImpersonation": decodedUser.IsImpersonation == "True",
        "Ghost": decodedUser.Ghost == "True",
        "RegionalAffliationCode": decodedUser.RegionalAffliationCode ? decodedUser.RegionalAffliationCode : ""
      }
    } else if (this.analyticsService.getDataLayerValue('TID') != '' && this.analyticsService.getDataLayerValue('TID') != Constants.GuidEmpty) {
      user_additional_info = {
        "Loggedout": true
      }
    }

    let utm_params = this.helperService.getUTMParameters();
    let merged_params = { ...user_info_params, ...user_additional_info, ...utm_params };
    if (sessionStorage['externalUserIdentifier']) {
      let externalUserIdParams = {
        "externalUserIdentifier": sessionStorage['externalUserIdentifier']
      }
      merged_params = { ...merged_params, ...externalUserIdParams };
      sessionStorage.removeItem('externalUserIdentifier');
    }
    this.analyticsService.trackCustomEvent(merged_params);
    this.analyticsService.identifyUser(this.TID);
    if (this.TID && this.TID != Constants.GuidEmpty) {
      this.analyticsService.addUserProperties(merged_params);
    }
  }

  loadToolBarControls(population?: string): void {
    this.toolbarControlsForMasthead = {
      population: {
        value: population != '' ? population : SelfService.PopulationAll,
        callbackFnName: "onPopulationClick"
      },
      menus: [
        {
          items: [
            {
              name: this.resourceStrings['Aon.SelfService.LogOff'],
              iconClass: "fa-arrow-right-from-bracket",
              callbackFnName: "logOut"
            }
          ]
        }
      ]
    }
  }

  onPopulationClick(): void {
    const modalRef = this.selfServiceService.openModal(SelfServiceManagePopulationTemplateModalComponent, this.resourceStrings['Aon.SelfService.PopulationVisibility.HeaderLabel'], "md");
    const componentInstance: SelfServiceManagePopulationTemplateModalComponent = modalRef.componentInstance;
    componentInstance.headerLabel = this.resourceStrings['Aon.SelfService.PopulationPreview'];
    componentInstance.isMultiSelectPopulation = false;
    componentInstance.selectedPopulation = this.selfServiceService.selectedPopulationFromSessionCache;
    componentInstance.onSubmitPopulation.subscribe({
      next: (data) => {
        this.loadToolBarControls(data.selectedPopulation);
        this.selfServiceService.onPopulationPreviewChange.next(data.selectedPopulation);
        sessionStorage.setItem(SessionStorageKey.SelectedPopulation, data.selectedPopulation);
        modalRef.dismiss();
      }
    })
  }
}
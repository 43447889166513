import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/login';
import { AuthGuard } from './core/guards';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { VbGlobalErrorPageComponent } from './shared/vb-global-error-page/vb-global-error-page.component';
import { MarketingPreferenceComponent } from './shared/marketing-preference/marketing-preference.component';
import { ClientCodeNotFoundComponent } from './shared/client-code-not-found/client-code-not-found.component';
import { MastheadInboxComponent } from './core/masthead/masthead-inbox/masthead-inbox.component';
import { BenefitDetailsComponent } from './shared/benefit-details/benefit-details.component';
import { ClientContentPageComponent } from './modules/client/client-content-page/client-content-page.component';
import { SsoRouterComponent } from './modules/sso-router/sso-router.component';
import { UrlGuard } from './core/guards/url.guard';
import { CallbackComponent } from './modules/callback/callback.component';
import { DefaultRouteGuard } from './core/guards/defaultRoute.guard';
import { PreloadingModulesService } from 'src/app/shared/services/preloading-modules.service';
import { SplashScreenComponent } from './modules/home/splash-screen/splash-screen.component';
import { CustomLoggedOffComponent } from './modules/client/custom-logged-off/custom-logged-off.component';

sessionStorage.setItem('enableVanityURLForMultiTenant', window['BootstrapRequestContext']().enableVanityURLForMultiTenant);
sessionStorage.setItem('clientCode', window['BootstrapRequestContext']().clientCode);

let enableVanityURLForMultiTenant: boolean = (sessionStorage.getItem("enableVanityURLForMultiTenant") === 'true');
let clientCode = sessionStorage.getItem("clientCode");
let allowAnonymousAccess = window['BootstrapRequestContext']().allowAnonymousAccess;
let siteName = window['BootstrapRequestContext']().siteDisplayName;
const canActivateForArticle = window['BootstrapRequestContext']().enableArticlePreAuthentication ? [] : [AuthGuard];
const canActivateForWrapper = window['BootstrapRequestContext']().enableWrapperPreAuthentication ? [] : [AuthGuard];
const canActivateForCampaign = window['BootstrapRequestContext']().enableCampaignPreAuthentication ? [] : [AuthGuard];

let routes: Routes = [];
let currentRoutePaths: Routes;
if (allowAnonymousAccess === false) {
  currentRoutePaths = [
    { path: '', pathMatch: 'full', loadChildren: () => import(/* webpackChunkName: "home" */'./modules/home/home.module').then(m => m.HomeModule), data: { title: siteName + ' ' + 'Home' }, canActivate: [DefaultRouteGuard] },
    { path: 'home', loadChildren: () => import(/* webpackChunkName: "home" */'./modules/home/home.module').then(m => m.HomeModule), data: { title: siteName + ' ' + 'Home' } },
    { path: 'login', component: LoginComponent, data: { title: 'Login' } },
    { path: 'loggedoff', component: CustomLoggedOffComponent, data: { title: 'Custom Logged Off' } },
    { path: 'benefit', loadChildren: () => import(/* webpackChunkName: "benefit" */ './modules/benefit/benefit.module').then(m => m.BenefitModule), canActivate: [AuthGuard], data: { preload: true } },
    { path: 'category/:id', loadChildren: () => import(/* webpackChunkName: "category" */ './modules/category/category.module').then(m => m.CategoryModule), canActivate: [AuthGuard], data: { breadcrumb: { alias: 'homebreadcrumb' }, title: 'Category' } },
    { path: 'product/:id', loadChildren: () => import(/* webpackChunkName: "product" */'./modules/product/product.module').then(m => m.ProductModule), canActivate: [AuthGuard], data: { breadcrumb: { alias: 'homebreadcrumb' }, title: 'Product' } },
    { path: 'quote/:id', loadChildren: () => import(/* webpackChunkName: "quote" */ './modules/quote/quote.module').then(m => m.QuoteModule), runGuardsAndResolvers: 'always', canActivate: [AuthGuard], data: { title: 'Quote Page', preload: true } },
    { path: 'quote', loadChildren: () => import(/* webpackChunkName: "quote" */ './modules/quote/quote.module').then(m => m.QuoteModule), runGuardsAndResolvers: 'always', canActivate: [AuthGuard], data: { title: 'Quote Page', preload: true } },
    { path: 'profile', loadChildren: () => import(/* webpackChunkName: "profile" */ './modules/profile/profile.module').then(m => m.ProfileModule), runGuardsAndResolvers: 'always', canActivate: [AuthGuard], data: { title: 'Profile', preload: true } },
    { path: 'account', loadChildren: () => import(/* webpackChunkName: "account" */ './modules/account/account.module').then(m => m.AccountModule) },
    { path: 'trs', loadChildren: () => import(/* webpackChunkName: "trs" */ './modules/trs/trs.module').then(m => m.TrsModule), canActivate: [AuthGuard], data: { title: 'trs', preload: true } },
    { path: 'claim', loadChildren: () => import(/* webpackChunkName: "claim" */ './modules/claim/claim.module').then(m => m.ClaimModule), canActivate: [AuthGuard], data: { preload: true } },
    { path: 'claim/:id', loadChildren: () => import(/* webpackChunkName: "claim" */ './modules/claim/claim.module').then(m => m.ClaimModule), canActivate: [AuthGuard], data: { preload: true } },
    { path: 'marketingpreference', component: MarketingPreferenceComponent, canActivate: [AuthGuard], data: { title: 'Marketing preference' } },
    { path: 'inbox', loadChildren: () => import(/* webpackChunkName: "claim" */ './core/masthead/masthead-inbox/masthead-inbox.module').then(m => m.MastheadInboxModule), canActivate: [AuthGuard], canDeactivate: [AuthGuard], data: { title: 'Inbox' } },
    /*    { path: 'demo', loadChildren: () => import(*//* webpackChunkName: "demo" *//*'./modules/demo/demo.module').then(m => m.DemoModule) },*/
    { path: 'error', component: VbGlobalErrorPageComponent, data: { title: 'error' } },
    { path: 'benefit-details', component: BenefitDetailsComponent, data: { title: 'Benefit Details' } },
    { path: 'client-content-page', component: ClientContentPageComponent, data: { title: 'Client Content' } },
    { path: 'ssorouter', component: SsoRouterComponent, canActivate: [AuthGuard], data: { title: 'SSO Router' } },
    { path: 'callback', component: CallbackComponent, data: { title: 'Callback' } },
    { path: 'article', loadChildren: () => import(/* webpackChunkName: "article" */ './modules/article/article.module').then(m => m.ArticleModule), data: { title: 'Articles', preload: true }, canActivate: canActivateForArticle },
    { path: 'wrapper', loadChildren: () => import(/* webpackChunkName: "wrapper" */ './modules/wrapper/wrapper.module').then(m => m.WrapperModule), data: { title: 'Wrapper' }, canActivate: canActivateForWrapper },
    { path: 'mybenefits', loadChildren: () => import(/* webpackChunkName: "benefit-dashboard" */ './modules/benefit-dashboard/benefit-dashboard.module').then(m => m.BenefitDashboardModule), data: { title: 'Benefit-dashboard' }, canActivate: canActivateForWrapper },
    { path: 'splashscreen', component: SplashScreenComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'splashscreenbreadcrumb' }, title: 'Splash Screen' } },
    { path: 'campaign', loadChildren: () => import(/* webpackChunkName: "campaign" */ './modules/campaign/campaign.module').then(m => m.CampaignModule), data: { title: 'Campaign', preload: true }, canActivate: canActivateForCampaign },
    { path: 'selfservice', loadChildren: () => import(/* webpackChunkName: "selfservice" */ './modules/self-service/self-service.module').then(m => m.SelfServiceModule), runGuardsAndResolvers: 'always', canActivate: [AuthGuard], data: { title: 'Self Service', preload: true } },
    { path: 'globalbenefitslibrary', loadChildren: () => import(/* webpackChunkName: "globalbenefitslibrary" */ './modules/global-benefits-library/global-benefits-library.module').then(m => m.GlobalBenefitsLibraryModule), data: { title: 'GlobalBenefitsLibrary' } },
    // otherwise redirect to home,
    { path: '**', component: PageNotFoundComponent }
  ];
}
else {
  currentRoutePaths = [
    { path: '', pathMatch: 'full', loadChildren: () => import(/* webpackChunkName: "home" */'./modules/home/home.module').then(m => m.HomeModule), data: { title: siteName + ' ' + 'Home' }, canActivate: [DefaultRouteGuard] },
    { path: 'home', loadChildren: () => import(/* webpackChunkName: "home" */'./modules/home/home.module').then(m => m.HomeModule), data: { breadcrumb: { alias: 'homebreadcrumb' }, title: siteName + ' ' + 'Home' } },
    { path: 'login', component: LoginComponent, data: { title: 'Login' } },
    { path: 'loggedoff', component: CustomLoggedOffComponent, data: { title: 'Custom Logged Off' } },
    { path: 'benefit', loadChildren: () => import(/* webpackChunkName: "benefit" */ './modules/benefit/benefit.module').then(m => m.BenefitModule), canActivate: [AuthGuard] },
    { path: 'category/:id', loadChildren: () => import(/* webpackChunkName: "category" */ './modules/category/category.module').then(m => m.CategoryModule), data: { title: 'Category' } },
    { path: 'product/:id', loadChildren: () => import(/* webpackChunkName: "product" */'./modules/product/product.module').then(m => m.ProductModule), data: { title: 'Product' } },
    { path: 'quote/:id', loadChildren: () => import(/* webpackChunkName: "quote" */ './modules/quote/quote.module').then(m => m.QuoteModule), runGuardsAndResolvers: 'always', data: { title: 'Quote Page', preload: true } },
    { path: 'profile', loadChildren: () => import(/* webpackChunkName: "profile" */ './modules/profile/profile.module').then(m => m.ProfileModule), runGuardsAndResolvers: 'always', data: { title: 'Profile' } },
    { path: 'account', loadChildren: () => import(/* webpackChunkName: "account" */ './modules/account/account.module').then(m => m.AccountModule) },
    { path: 'marketingpreference', component: MarketingPreferenceComponent, canActivate: [AuthGuard], data: { title: 'Marketing preference' } },
    { path: 'inbox', component: MastheadInboxComponent, canActivate: [AuthGuard], data: { title: 'Inbox' } },
    { path: 'wrapper', loadChildren: () => import(/* webpackChunkName: "wrapper" */ './modules/wrapper/wrapper.module').then(m => m.WrapperModule), data: { title: 'Wrapper' } },
    { path: 'mybenefits', loadChildren: () => import(/* webpackChunkName: "benefit-dashboard" */ './modules/benefit-dashboard/benefit-dashboard.module').then(m => m.BenefitDashboardModule), data: { title: 'Benefit-dashboard' } },
    { path: 'callback', component: CallbackComponent, data: { title: 'Callback' } },
    { path: 'splashscreen', component: SplashScreenComponent, data: { breadcrumb: { alias: 'splashscreenbreadcrumb' }, title: 'Splash Screen' } },
    { path: 'error', component: VbGlobalErrorPageComponent, data: { title: 'error' } },
    { path: 'globalbenefitslibrary', loadChildren: () => import(/* webpackChunkName: "globalbenefitslibrary" */ './modules/global-benefits-library/global-benefits-library.module').then(m => m.GlobalBenefitsLibraryModule), data: { title: 'GlobalBenefitsLibrary' } },

    // otherwise redirect to home
    { path: '**', canActivate: [AuthGuard], component: PageNotFoundComponent }
  ];
}
if (enableVanityURLForMultiTenant) {
  if (clientCode == null || clientCode == "" || clientCode == "null" || clientCode == "undefined") {
    routes = [
      { path: '**', data: { title: siteName }, canActivate: [UrlGuard], component: ClientCodeNotFoundComponent },
    ]
  }
  else {
    routes = [
      { path: '', data: { title: siteName }, component: ClientCodeNotFoundComponent, pathMatch: 'full' },
      { path: 'home', data: { title: siteName }, component: ClientCodeNotFoundComponent, pathMatch: 'full' },
      {
        path: ':clientcode', data: { breadcrumb: { skip: true } },
        children: currentRoutePaths

      }
    ];
  }


}
else {
  routes = currentRoutePaths;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', anchorScrolling: 'enabled', scrollPositionRestoration: 'top', preloadingStrategy: PreloadingModulesService })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        @switch(templateMode) {
        @case (TemplateMode.Add) {
        {{resourceStrings['Aon.SelfService.CTA.Modal.Add.Header.Label']}}
        }
        @case (TemplateMode.Edit) {
        {{resourceStrings['Aon.SelfService.CTA.Modal.Edit.Header.Label']}}
        }
        }
    </h4>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="basic-card edit-state">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="ctaForm">
                    @if (canDisplayField('itemTitle')) {
                    @if (isValidationFailed(ctaForm,'itemTitle')) {
                    <div class="alert contextual error">
                        <div role="alert">
                            <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                            <span
                                [innerHTML]="(ctaForm?.controls?.itemTitle?.errors?.required ? resourceStrings['Aon.Validation.Required.ErrorMessage']: resourceStrings['Aon.Validation.Invalid.ErrorMessage'] ) | textTransform : {fieldName:resourceStrings['Aon.Common.Title']} | safeHtml"></span>
                        </div>
                    </div>
                    }
                    <div class="form-group">
                        <label for="itemTitle"><span
                                [innerHTML]="resourceStrings['Aon.Common.Title']+addRequired(ctaForm, 'itemTitle') | safeHtml"></span></label>
                        <input formControlName="itemTitle" id="itemTitle"
                            [attr.aria-invalid]="isValidationFailed(ctaForm,'itemTitle')" type="text">
                    </div>
                    }
                    @if (canDisplayField('actionType')) {
                    <div class="form-group">
                        <label for="actionType"><span
                                [innerHTML]="resourceStrings['Aon.SelfService.CTA.ActionType.Label'] | safeHtml"></span></label>
                        <p class="xs"
                            [innerHTML]="resourceStrings['Aon.SelfService.CTA.ActionType.Description'] | safeHtml">
                        </p>
                        <div class="aon-form-input-wrapper select">
                            <select formControlName="actionType" id="actionType">
                                <option value="" [innerHTML]="resourceStrings['Aon.Common.PleaseChoose']" hidden>
                                </option>
                                <option value="RedirectAction">Redirect</option>
                            </select>
                        </div>
                    </div>
                    }
                    @if (canDisplayField('linkUrl')) {
                    <div class="form-group">
                        <label for="linkUrl"><span
                                [innerHTML]="resourceStrings['Aon.SelfService.CTA.TargetURL.Label'] | safeHtml"></span></label>
                        <p class="xs"
                            [innerHTML]="resourceStrings['Aon.SelfService.CTA.TargetURL.Description'] | safeHtml">
                        </p>
                        <input formControlName="linkUrl" id="linkUrl" type="text">
                    </div>
                    }
                    @if (canDisplayField('displayStyle')) {
                    <div class="form-group">
                        <label for="displayStyle"><span
                                [innerHTML]="resourceStrings['Aon.SelfService.CTA.DisplayStyle.Label'] | safeHtml"></span></label>
                        <div class="aon-form-input-wrapper select">
                            <select formControlName="displayStyle" id="displayStyle">
                                <option value="" [innerHTML]="resourceStrings['Aon.Common.PleaseChoose']" hidden>
                                </option>
                                <option [ngValue]="false">Button</option>
                                <option [ngValue]="true">Link</option>
                            </select>
                        </div>
                    </div>
                    }
                    @if (canDisplayField('cssClass')) {
                    <div class="form-group">
                        <label for="cssClass"><span
                                [innerHTML]="resourceStrings['Aon.SelfService.CTA.CSSClass.Label'] | safeHtml"></span></label>
                        <p class="xs"
                            [innerHTML]="resourceStrings['Aon.SelfService.CTA.CSSClass.Description'] | safeHtml">
                        </p>
                        <input formControlName="cssClass" id="cssClass" type="text">
                    </div>
                    }

                    @if (canDisplayField('displayText')) {
                    <div class="form-group">
                        <label for="displayText"><span
                                [innerHTML]="resourceStrings['Aon.SelfService.CTA.DisplayText.Label'] | safeHtml"></span></label>
                        <p class="xs"
                            [innerHTML]="resourceStrings['Aon.SelfService.CTA.DisplayText.Description'] | safeHtml">
                        </p>
                        <input formControlName="displayText" id="displayText" type="text">
                    </div>
                    }

                    @if (canDisplayField('accessibleDescription')) {
                    <div class="form-group">
                        <label for="accessibleDescription"><span
                                [innerHTML]="resourceStrings['Aon.SelfService.CTA.AccessibleDescription.Label'] | safeHtml"></span></label>
                        <p class="xs"
                            [innerHTML]="resourceStrings['Aon.SelfService.CTA.AccessibleDescription.Description'] | safeHtml">
                        </p>
                        <input formControlName="accessibleDescription" id="accessibleDescription" type="text">
                    </div>
                    }
                </form>
            </div>
        </div>
    </div>
</div>
@if (onSubmitErrorMessage != '') {
<div class="alert contextual error m-t-20">
    <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
    <span [innerHTML]="onSubmitErrorMessage | safeHtml"></span>
</div>
}
<div class="button-sleeve">
    <button type="button" class="prominent m-t-32" (click)="saveCTA()"
        [innerHTML]="resourceStrings['Aon.Common.OK'] | safeHtml"></button>
    <button type="button" class="ghost m-t-32" (click)="activeModal.dismiss('Cancel click')"
        [innerHTML]="resourceStrings['Aon.Common.Cancel'] | safeHtml"></button>
</div>
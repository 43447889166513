import { Component, OnInit, Inject, HostListener, Injector, OnDestroy, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { CommonService } from 'src/app/core/services';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/shared/models/product.model';
import { JwtHelper } from 'src/app/core/guards/jwt.helper';
import { Router } from '@angular/router';
import { BootstrapRequestContext } from 'tbs-typings';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { combineLatestWith } from 'rxjs';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { Constants, SessionStorageKey } from 'src/app/shared/models/constants';
import { CategoryType } from 'src/app/shared/models/categoryType.model';
import { ProfileService } from 'src/app/modules/profile/profile.service';

@Component({
  selector: 'app-masthead-delegate',
  templateUrl: './masthead-delegate.component.html'
})
export class MastheadDelegateComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @ViewChild('noEligibleProductsWarningContent') noEligibleProductsWarningContent: any;
  closeResult: string;
  faLifeRing: any;
  faUser: any;
  isNavbarCollapsed = true;
  products: Product[];
  categories: CategoryType[];
  preferredName: string;
  changeIcon1: boolean;
  changeIcon2: boolean;
  resourcestrings = {};
  selectedProductPagePath: string;
  selectedProductPageGUID: string;
  selectedProductEnglishName: string;
  selectedDocumentName: string;
  launchQuoteFromHomePage: boolean;
  campaignKey: string;
  headMenuObject: any;
  TID: string;
  productsCount: number = 0;
  hideLanguageSelection: boolean = false;
  mobileMaxWidth = 576; //Max scree size for mobile
  isMobile = false;
  el_overlay: any;
  isFailedPayment: boolean;
  uiConfig: UIConfigurationType;
  showInbox: boolean;
  isFromMobileApp: boolean;
  unreadMessage: number;
  hasGracePeriod: boolean;
  closeBenefit: boolean;
  isCategorizationEnabled: boolean = false;
  currentComponentName = 'masthead';
  isImpersonation: string;
  showSplashPageMenu: boolean = false;
  showMegaMenu: boolean = true;
  hideBenefitMenu: boolean = false;
  dropDownAutoClose: boolean | string = true;
  actAsDelegate: boolean = false;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  constructor(
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly injector: Injector,
    public authenticationService: AuthenticationService,
    public commonService: CommonService,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly modalService: NgbModal,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly paymentService: PaymentService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly profileService: ProfileService
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
    this.addResourceStringKeys(this.resService.mastheadStringKeys);

    this.globalObjectsService.Products
      .pipe(combineLatestWith(
        this.globalObjectsService.CategorizationEnabled,
        this.globalObjectsService.Categories
      ), takeUntil(this.unsubscribe))
      .subscribe(([r, isCategorizationEnabled, Categories]) => {
        this.products = r;
        this.isCategorizationEnabled = isCategorizationEnabled;
        this.categories = Categories;
        this.initBenefitsList();
      });

    this.changeIcon1 = false;
    this.changeIcon2 = false;
    this.headMenuObject = JSON.parse(bootstrapRequestContext.mastheadMenu);
  }

  toggleNavBar() {
    this.helperService.narBarCollapseState.next(!this.isNavbarCollapsed)
  }

  ngOnInit(): void {
    this.globalObjectsService.checkHeaderFooterConfig.subscribe(value => {
      if (value) {
        this.helperService.narBarCollapseState.subscribe((isCollapsed) => {
          this.isNavbarCollapsed = isCollapsed;
        });
        this.campaignKey = this.helperService.getCampaignKey();
        this.uiConfig = this.uiconfigrationService.getUIConfiguration();
        this.isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';
        // Hide Inbox in member site for mobile app
        this.showInbox = this.uiConfig.uiConfiguration.enableInbox && !this.isFromMobileApp;
        this.getCurrentPathRoleSettingStatus(this.injector);
        this.loadResourceStringAsObservable()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => this.getResouceStringsAsObject(res));

        this.authenticationService.currentUser
          .subscribe((user) => {
            if (user?.id_token) {
              const jwtHelper = new JwtHelper();
              const decodedUser = jwtHelper.decodeToken(user.id_token);

              if (this.authenticationService.isLogin) {
                this.showMegaMenu = true;
              }
              this.updateAnalyticsValues(decodedUser);
              this.globalObjectsService.reloadShoppingCart.next(true);
              this.globalObjectsService.reloadProfileGracePeriod.next(true);
            }
          });

        this.getCategories();
        this.getScreenView();
        this.getEmployeeMEssageCount();
        
      }
      if (this.helperService.isLoggedOn()) {
        const jwtHelper = new JwtHelper();
        const user = JSON.parse(sessionStorage.getItem('currentUser'));
        this.isImpersonation = jwtHelper.decodeToken(user.id_token).IsImpersonation;
      }
      if (this.uiConfig.uiConfiguration.showSplashPage && this.isImpersonation == 'False') {
        this.showSplashPageMenu = true;
      }
      else {
        this.showSplashPageMenu = false;
      }

      this.globalObjectsService.delegateEmployeeUpdated
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(delegateEmployee => {
        if (delegateEmployee) {
          this.preferredName = delegateEmployee.firstName;
        }
      })

      this.getCompactEmployeeInformation();
      
    });

    this.setCommonService();
    
  }

  getCategories() {
    //checkHeaderFooterConfig only be reset after user login or logout no need to further watch currentUser
    const parentPath = '/Categories';
    if (this.bootstrapRequestContext.allowAnonymousAccess || this.authenticationService.isLogin) {
      this.commonService.getCategories(parentPath)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.updateCat(data);
        });
    }
  }

  getEmployeeMEssageCount() {
    this.notificationService.getMessage()
      .pipe(
        mergeMap(() => this.loadResourceStringAsObservable()),
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => this.getResouceStringsAsObject(res));

    //Get EmployeeMEssageCount post-Auth only if it's enabled, checkHeaderFooterConfig only be reset after user login or logout no need to further watch currentUser
    if (this.showInbox && this.authenticationService.isLogin) {
      this.commonService.GetEmployeeMessageCount()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => {
          this.unreadMessage = data.unreadCount;
          if (this.unreadMessage != 0) {
            this.commonService.unreadCount = true;
          }
          else {
            this.commonService.unreadCount = false;
          }
        });
    }
  }

  getCompactEmployeeInformation() {
    if (this.authenticationService.isLogin) {
      this.globalObjectsService.compactEmployeeInformation
        .pipe(
          combineLatestWith(this.authenticationService.currentUser),
          take(1))
        .subscribe(([data, user]) => {
          let jwtHelper = new JwtHelper();
          if (user?.id_token) {
            let parsedToken = jwtHelper.decodeToken(user.id_token);
            let delegateEmployeeSettings = (parsedToken.DelegateEmployeeSettings != null) ? JSON.parse(parsedToken.DelegateEmployeeSettings) : null;
            if (delegateEmployeeSettings) {
              delegateEmployeeSettings = this.helperService.toCamel(delegateEmployeeSettings);
              if (delegateEmployeeSettings.delegateForEmployees.length > 0) {
                const curSelDelegateEmployee = sessionStorage.getItem(SessionStorageKey.ActDelegateEmployee);
                if (curSelDelegateEmployee != null) {
                  const selDelegateEmployee = delegateEmployeeSettings.delegateForEmployees.find(d => d.employeeID == curSelDelegateEmployee);
                  if (selDelegateEmployee) {
                    this.preferredName = selDelegateEmployee.firstName;
                  }
                }
              }
            }
          }

          if (this.authenticationService.isLogin && data == null) {
            this.commonService.getCompactEmployeeInformation()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(data => {
                if (!this.preferredName) {
                  this.preferredName = data?.preferredName;
                }
                this.globalObjectsService.compactEmployeeInformation.next(data);
              })
          }
        })
    }
  }

  setCommonService() {
    this.commonService.resetLanguageList.subscribe(rl => {
      //show site whole list after logout
      if (rl
        && this.hideLanguageSelection
        && !this.helperService.isLoggedOn()
        && sessionStorage.getItem('AllowedLanguages')
        && JSON.parse(sessionStorage.getItem('AllowedLanguages')).allowedLanguages.length > 1
      ) {
        this.hideLanguageSelection = false;
      }
    });

    if (this.bootstrapRequestContext.enableArticlePreAuthentication && this.helperService.isLoggedOn()) {
      this.commonService.resetOpenAccessDetails.next(true);
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
    this.showMegaMenu = false;
    this.closeModelAddOverlay(false);
  }

  updateCat(data) {
    this.globalObjectsService.Categories.next(this.commonService.getDefinedCategories(data));
    this.globalObjectsService.Products.next(this.commonService.getProductsFromVirtualCategory(data));
    this.globalObjectsService.CategorizationEnabled.next(this.commonService.isCategorizationEnabled(data));
    this.globalObjectsService.Products.pipe(take(1))
      .subscribe(products => {
        let hasProducts = this.products && this.products.length > 0;
        if (this.bootstrapRequestContext.blockUserIfNoProductsAvailable && this.authenticationService.isLogin && !hasProducts) {
          //No available products
          let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title'
          };
          this.modalService.open(this.noEligibleProductsWarningContent, ngbModalOptions);
        }
      });
  }

  ngOnChanges(): void {
    const parentPath = '/Categories';
    this.commonService.getCategories(parentPath)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.updateCat(data);
      });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    let urL = this.router.url.valueOf();
    if (!urL.includes(this.baseUrl + "/quote") || !this.uiConfig.uiConfiguration.showAlertPopup || (urL.includes("/complete"))) {
      this.authenticationService.logout().subscribe(() => {
        this.helperService.logoutRedirection();
      });
    } else if (this.uiConfig.uiConfiguration.showAlertPopup) {
      this.helperService.logoutRedirection();
    }
  }

  public benefits: Array<{
    categoryName: string,
    icon: string,
    icon2: string,
    groupBenefits: Product[];
  }> = [];
  private initBenefitsList() {
    if (this.products) {
      let categories = [];
      this.benefits = [];
      this.products.forEach(product => {
        if (product.category) {
          if (!categories.includes(product.category)) {
            categories.push(product.category);
            let gbs = [];
            this.benefits.push({
              categoryName: product.category,
              icon: product.icon,
              icon2: product.icon2,
              groupBenefits: gbs
            });
          }
          let findGroup = this.benefits.findIndex(b => b.categoryName == product.category);
          this.benefits[findGroup].groupBenefits.push(product);
        }
      });
    }
  }

  get ShowMastHeadBenefitsMenu(): boolean {
    return this.benefits && this.benefits?.length > 0 && !this.hideBenefitMenu && (this.headMenuObject.benefits && this.showAfterLoginCheck());
  }

  showAfterLoginCheck(): boolean {
    if (!this.helperService.AllowAnonymousAccess() && !this.authenticationService.isLogin) {
      return false;
    }
    return true;
  }

  get showArticleWrapperDetails(): boolean {
    if (this.bootstrapRequestContext.enableArticlePreAuthentication || !this.bootstrapRequestContext.enableArticlePreAuthentication && this.showAfterLoginCheck()) {
      return true;
    }
    return false;
  }

  onCartUpdated(productsCount: number): void {
    this.productsCount = productsCount;
  }

  onAllowedLanguagesLoaded(hideLanguageSelection: boolean): void {
    this.hideLanguageSelection = hideLanguageSelection;
  }

  onGracePeriodUpdated(hasGracePeriod: boolean): void {
    this.hasGracePeriod = hasGracePeriod;
  }

  isAsiaVB(): boolean {
    return this.helperService.IsVBAsia();
  }

  isUsVB(): boolean {
    return this.helperService.IsUSVB();
  }

  isOlb(): boolean {
    return this.helperService.IsOLB();
  }

  closemodalcross(statusofclick: boolean) {
    this.isNavbarCollapsed = statusofclick;
  }

  truncate(input) {
    if (input && input.length > 10) {
      return input.substring(0, 10) + '...';
    }
    return input;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenView();
  }

  getScreenView() {
    this.isMobile = window?.innerWidth < this.mobileMaxWidth;
  }

  closeModelAddOverlay(event) {
    if (!event) {
      if (this.headMenuObject.benefits) {
        this.closeBenefit = true;
      }
      if (document.querySelector('.bg-overlay')) {
        document.body.removeChild(document.querySelector('.bg-overlay'));
      }
    } else {
      if (this.headMenuObject.benefits) {
        this.closeBenefit = false;
      }
      this.el_overlay = document.createElement('span');
      this.el_overlay.className = 'bg-overlay';
      document.body.appendChild(this.el_overlay);
    }
  }

  clickedDropDown: NgbDropdown;
  closeOtherDropdowns(clickedDropdown: NgbDropdown) {
    this.clickedDropDown = clickedDropdown;
    setTimeout(() => {
      let totalPlanCon = document.querySelectorAll<HTMLElement>(".qContent").length;
      for (let i = 0; i < totalPlanCon; i++) {
        let offsetHeightCn = document.querySelectorAll<HTMLElement>(".qContent")[i].offsetHeight;
        let scrollHeightCn = document.querySelectorAll<HTMLElement>(".qContent")[i].scrollHeight;
        if (scrollHeightCn == offsetHeightCn) {
          document.querySelectorAll<HTMLElement>('.qContent')[i].classList.add("disableTooltip");
        }

      }
    }, 100);

    this.dropdowns.toArray().forEach(el => {
      if (el != clickedDropdown)
        el.close();
    });

    this.keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown);
  }

  /**
   * To handle the custom browser scroll event.
   * Nav drop down getting close when the user clicks on the scroll bar
   * So, Adding some custom events to track the movement and making it open
   */
  keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown: NgbDropdown): void {
    let scrollEventFired: boolean = false;
    try {
      const self = this;
      const eventHandlers = {
        scroll() {
          self.dropDownAutoClose = false;
          scrollEventFired = true;
        },
        mousedown() {
          // 3 Step
          document.addEventListener("scroll", eventHandlers.scroll);
          self.dropDownAutoClose = true;
          scrollEventFired = false;
        },
        mouseup() {
          self.dropDownAutoClose = true;
          if (!scrollEventFired) {
            eventHandlers._clear();
            setTimeout(() => {
              if (clickedDropdown.isOpen()) {
                self.keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown);
              }
            }, 10);
          }
        },
        _clear() {
          document.removeEventListener("mousedown", eventHandlers.mousedown);
          document.removeEventListener("scroll", eventHandlers.scroll);
          document.removeEventListener("mouseup", eventHandlers.mouseup);
        }
      }

      // 1 Step
      document.addEventListener("mousedown", eventHandlers.mousedown);
      // 2 Step
      document.addEventListener("mouseup", eventHandlers.mouseup);
    } catch { }
  }

  checkMegaMenuHasContent(event) {
    this.showMegaMenu = event;
  }

  closeOurValues(event, dropdwon) {
    if (event)
      dropdwon.close();
    else
      dropdwon.open();
  }

  updateAnalyticsValues(decodedUser: any) {

    let isLogoutUser = !this.authenticationService.isLogin && this.analyticsService.verifyDataLayerValue('IsAuthenticated', 'true');

    if (isLogoutUser) {
      this.TID = this.analyticsService.getDataLayerValue('TID');
    } else {
      this.TID = decodedUser.TID;
    }

    if (this.TID == Constants.GuidEmpty && this.helperService.getUrlParameter('TID')) {
      this.TID = this.helperService.getUrlParameter('TID');
    }

    let externalTID = this.helperService.getUrlParameter('external_TID');
    if (!externalTID) {
      externalTID = Constants.GuidEmpty;
    }

    let user_info_params = {
      "event": "userinfo-access",
      "IsAuthenticated": this.TID && this.TID != Constants.GuidEmpty,
      "PreferredCulture": decodedUser.PreferredCulture,
      "ClientCode": decodedUser.ClientCode,
    };

    if (decodedUser.IsSso && decodedUser.IsSso != "False") {
      this.analyticsService.updateDataLayer("IsSSO", decodedUser.IsSso);
    }

    let user_additional_info = {};

    user_info_params = {
      ...user_info_params,
    }

    if (this.TID != Constants.GuidEmpty) {
      user_additional_info = {
        "TID": this.TID,
        "externalTID": externalTID,
        "IsImpersonation": decodedUser.IsImpersonation == "True",
        "Ghost": decodedUser.Ghost == "True",
        "RegionalAffliationCode": decodedUser.RegionalAffliationCode ? decodedUser.RegionalAffliationCode : ""
      }
    } else if (this.analyticsService.getDataLayerValue('TID') != '' && this.analyticsService.getDataLayerValue('TID') != Constants.GuidEmpty) {
      user_additional_info = {
        "Loggedout": true
      }
    }

    let utm_params = this.helperService.getUTMParameters();
    let merged_params = { ...user_info_params, ...user_additional_info, ...utm_params };
    if (sessionStorage['externalUserIdentifier']) {
      let externalUserIdParams = {
        "externalUserIdentifier": sessionStorage['externalUserIdentifier']
      }
      merged_params = { ...merged_params, ...externalUserIdParams };
      sessionStorage.removeItem('externalUserIdentifier');
    }
    this.analyticsService.trackCustomEvent(merged_params);
  }

}

import { Component, EventEmitter, HostListener, Inject, Injector, OnInit, Output, Input } from '@angular/core';
import { CommonService } from '../services';
import { LanguageSelectionData } from '../../shared/models';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { TBSBaseComponent } from '../../shared/models/tbsBaseComponent';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { LanguageCulture } from '../../shared/models/languageCulture.model';
import { BootstrapRequestContext } from 'tbs-typings';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { GlobalObjectsService } from '../../shared/services/global-objects.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ClaimSaveAlertComponent } from '../../modules/claim/claim-save-alert/claim-save-alert.component';
import { SessionStorageKey } from '../../shared/models/constants';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html'
})
export class LanguageSelectionComponent extends TBSBaseComponent implements OnInit {
  @Output() hideLanguageSelection = new EventEmitter<boolean>();
  @Output() closeOtherDropdown = new EventEmitter<any>();
  @Input() dropDownAutoClose: boolean | string = true;

  allowedLanguage: any;
  filteredallowedLanguage: any;

  selectedLanguage: LanguageSelectionData;
  selectedLanguageCode: string;
  previousLanguageCode: string;

  mobile_max_width = 576; //Max scree size for mobile
  isMobile = false;

  constructor(private readonly injector: Injector,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly modalService: NgbModal,

    protected authenticationService: AuthenticationService,
    private readonly globalObjectsService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') protected bootstrapRequestContext: BootstrapRequestContext,
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenView();
  }

  getScreenView() {
    this.isMobile = window?.innerWidth < this.mobile_max_width;
  }

  ngOnInit() {
    this.getScreenView();
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));
    const url = new URL(window.location.href);
    const requestedLanguage = url.searchParams.get('lang');
    if (localStorage.getItem('SelectedLanguage') != null) {
      this.selectedLanguage = JSON.parse(localStorage.getItem('SelectedLanguage'));
    }
    if (sessionStorage.getItem('AllowedLanguages') != null) {
      this.setLanguagesBySession();
      if (this.selectedLanguage && requestedLanguage && this.selectedLanguage.cultureCode.toLowerCase() != requestedLanguage.toLowerCase()) {
        this.CheckRequestedLanguage(requestedLanguage);
      }
    } else {
      this.allowedLanguage = null;
      if (this.authenticationService.isLogin) {
        this.commonService.getAllowedLanguages().pipe(take(1)).subscribe((data) => {
          this.setAllowedLanguages(data, requestedLanguage);
        });
      } else {
        this.setAllowedLanguages(this.bootstrapRequestContext.languageSelection, requestedLanguage);
      }

    }
    
    this.commonService.resetLanguageList.subscribe(r => {
      if (r && sessionStorage.getItem('AllowedLanguages')) {
        this.setLanguagesBySession();
      }
    });

    this.commonService.resetSelectedLanguage.subscribe(r => {
      if (r) {
        let selectedLanguage = JSON.parse(localStorage.getItem('SelectedLanguage'));
        if (selectedLanguage) {
          this.SelectLanguage(selectedLanguage.cultureCode, selectedLanguage.cultureShortName);
        }
      }
    });
    if (this.helperService.isLoggedOn()) {
      this.updateLanguageSelection(this.selectedLanguage.cultureCode);
    }


  }
  /// Only render one “version” of a particular language in dropdown list.
  /// Ex: if we have two French cultures, fr-ca and fr-fr, only display one of them.
  /// Preferably where language and country codes match up. In instances where there is no match (like en-us and en-uk) just pick the first one.
  private filterAllowedLanguage() {
    let result = [];
    let allowedLanguages = this.allowedLanguage.allowedLanguages;
    for (let lang of allowedLanguages) {
      let languageCode = lang.cultureCode.substring(0, 2).toLowerCase();
      let bestCultureCode = languageCode + "-" + languageCode.toUpperCase();
      if (lang.cultureCode == bestCultureCode) {
        if (result.indexOf(lang) == -1) {
          result.push(lang);
        }
      }
    }
    for (let lang of allowedLanguages) {
      let languageCode = lang.cultureCode.substring(0, 2).toLowerCase();
      let filterdSelectedCul = result.filter((lang) => lang.cultureCode.indexOf(languageCode) > -1);
        if (filterdSelectedCul.length == 0) {
          result.push(lang);
      }

    }
    this.filteredallowedLanguage = result;
  }
  private setAllowedLanguages(data: any, requestedLanguage: any) {
    this.allowedLanguage = data;
    sessionStorage.setItem('AllowedLanguages', JSON.stringify(this.allowedLanguage));
    this.setLanguagesBySession();

    if (this.selectedLanguage && requestedLanguage && this.selectedLanguage.cultureCode.toLowerCase() != requestedLanguage.toLowerCase()) {
      this.CheckRequestedLanguage(requestedLanguage);
    }
    if (localStorage.getItem('SelectedLanguage') == null) {
      this.selectedLanguage = data.currentLanguage;
      if (this.selectedLanguage.cultureCode) {
        localStorage.setItem('SelectedLanguage', JSON.stringify(this.selectedLanguage)); // when does this happen? What about setDefaultLanguage?
      } 
    } else {
      this.selectedLanguage = JSON.parse(localStorage.getItem('SelectedLanguage'));
    }
    this.updatedSelectedLangeShortNameWhenNull();

  }

  private updatedSelectedLangeShortNameWhenNull() {
    if (this.selectedLanguage.cultureShortName == null || this.selectedLanguage.cultureShortName == '') {
      for (const language of this.allowedLanguage.allowedLanguages) {
        if (language.cultureCode.toLocaleLowerCase() == this.selectedLanguage.cultureCode.toLocaleLowerCase()) {
          this.selectedLanguage.cultureShortName = language.cultureShortName;
          if (this.selectedLanguage.cultureCode) {
            localStorage.setItem('SelectedLanguage', JSON.stringify(this.selectedLanguage));
          }
          break;
        }
      }
    }
  }

  private setLanguagesBySession() {
    this.allowedLanguage = JSON.parse(sessionStorage.getItem('AllowedLanguages'));
    this.filterAllowedLanguage();

    if (this.filteredallowedLanguage  && this.filteredallowedLanguage.length == 1) {
      this.hideLanguageSelection.emit(true);
    } else {
      this.hideLanguageSelection.emit(false);
    }

  }

  SelectLanguage(newLanguageCode: string, newLanguage: string) {
    if (this.selectedLanguage != null && this.selectedLanguage.cultureCode == newLanguageCode) {
      return;
    }
    if (!this.isClaimFlow()) {
      this.SetLanguage(newLanguageCode, newLanguage)
      let langURL = new URL(window.location.href);
      langURL.searchParams.set('lang', newLanguageCode);
      window.location.href = this.encodeHTML(langURL.href);

    } else {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: true,
        ariaLabelledBy: 'leave-flow-alert-modal-title'
      };
      ngbModalOptions.container = '#app-modal-anchor';
      this.modalService.open(ClaimSaveAlertComponent, ngbModalOptions);

      this.commonService.ClaimLanguageChange.subscribe((value) => {
        if (value) {
          this.SetLanguage(newLanguageCode, newLanguage)
          let langURL = new URL(window.location.href.split("details")[0] + 'details');
          let claimsable_recordid = window.location.href.split("claim")[1].split("details")[0].replace(/\//g, '');
          sessionStorage.removeItem(claimsable_recordid);
          sessionStorage.removeItem(SessionStorageKey.ClaimSelectedCurrency);
          sessionStorage.removeItem(SessionStorageKey.ClaimItems);
          langURL.searchParams.set('lang', newLanguageCode);
          window.location.href = this.encodeHTML(langURL.href);
        }
      })
    }

  }

  SetLanguage(newLanguageCode: string, newLanguage: string) {
    sessionStorage.removeItem('preferredCulture');
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith('MetaData.')) {
        sessionStorage.removeItem(key);
      }
    });
    this.previousLanguageCode = this.selectedLanguageCode;
    this.selectedLanguageCode = newLanguageCode;
    if (this.selectedLanguageCode && this.previousLanguageCode !== this.selectedLanguageCode) {
      this.selectedLanguage = {
        cultureCode: newLanguageCode,
        cultureShortName: newLanguage
      };
      localStorage.setItem('SelectedLanguage', JSON.stringify(this.selectedLanguage));
    } else if (!this.selectedLanguageCode) {
      console.log("language selection component, SetLanguage, selectedLanguage.cultureCode is null.")
    }
  }

  updateLanguageSelection(culture: string) {
    this.globalObjectsService.compactEmployeeInformation
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(emp => {
        if (emp) {
          let languageSelectors = new LanguageCulture();
          languageSelectors.culture = culture;
          languageSelectors.userName = emp.userName;
          this.commonService.UpdateLanguageSelection(languageSelectors).subscribe(data => {
          })
        }
      });
  }

  encodeHTML(value) {
    return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
  }

  ChangeLanguage(event: any) {
    sessionStorage.removeItem('preferredCulture');
    const newLanguage: LanguageSelectionData = this.allowedLanguage?.allowedLanguages.find(
      (item: LanguageSelectionData) => item.cultureShortName == event.target.value
    );
    this.SelectLanguage(newLanguage.cultureCode, newLanguage.cultureShortName);
  }

  closeOtherDropdowns(clickedDropdown) {
    this.closeOtherDropdown.emit(clickedDropdown);
    this.analyticsService.trackHeapEvent("Nav Bar Menu Clicked", "Site", "Nav Bar Menu Click", "Language Selection");
  }

  CheckRequestedLanguage(requestedLang: string) {
    this.allowedLanguage = JSON.parse(sessionStorage.getItem('AllowedLanguages'));
    this.filterAllowedLanguage();

    if (requestedLang && this.allowedLanguage?.allowedLanguages) {
      this.allowedLanguage.allowedLanguages.forEach((lang) => {
        if (lang.cultureCode.toLowerCase() == requestedLang.toLowerCase()) {
          const selectedLanguage = { cultureCode: lang.cultureCode, cultureShortName: lang.cultureShortName };
          localStorage.removeItem('SelectedLanguage');
          if (selectedLanguage.cultureCode) {
            localStorage.setItem('SelectedLanguage', JSON.stringify(selectedLanguage));
          } else {
            console.log("language selection component, CheckRequestedLanguage, selectedLanguage.cultureCode is null.")
          }
          window.location.reload();
        }
      });
    }
  }

  private isClaimFlow() {
    return location.href.includes("/claim/") && location.href.includes("/details/");
  }
}

import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, iif, of } from 'rxjs';
import { catchError, filter, mergeAll, mergeMap, takeUntil } from 'rxjs/operators';
import { EmployeeBenefitsType, EmployeeBenefitType } from 'src/app/shared/models/benefitReview.model';
import { SaveResult } from 'src/app/shared/models/saveResult.model';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { GlobalObjectsService } from '../../../shared/services/global-objects.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { MastheadService } from '../../services/masthead.service';
import { Product } from '../../../shared/models/product.model';
import { UiconfigrationService } from '../../../core/services/uiconfigration.service';
import { UIConfigurationType } from '../../../shared/models/uiconfigration.interface';
import { EmployeeRelationshipTypeNames } from '../../../shared/models/constants';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';

@Component({
  selector: 'app-masthead-cart',
  templateUrl: './masthead-cart.component.html'
})
export class MastheadCartComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @Output() onCartUpdated = new EventEmitter<number>();
  @Output('hideCartPopUp') hideCartPopUp: EventEmitter<any> = new EventEmitter();

  quotes: EmployeeBenefitType[] = [];
  totalCost_Today: number;
  currencyCode: string;
  currentEmployeeBenefitID: number;
  globalProducts: Product[];
  uiConfig: UIConfigurationType;

  // whosCovered:string[]=[];
  constructor(
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly ngxService: NgxUiLoaderService,
    private readonly mastheadService: MastheadService,
    private readonly authenticationService: AuthenticationService,
    private readonly injector: Injector,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly  globalojectService: GlobalObjectsService
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
    this.addResourceStringKeys(this.resService.mastheadCartStringKeys);
  }

  ngOnInit(): void {
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));

    if (this.helperService.isShoppingCartEnabled()) {
      of(
        this.globalojectService.reloadShoppingCart.pipe(
          filter((reloaded) => reloaded && this.authenticationService.isLogin),
          mergeMap(() => this.mastheadService.getGlobalCartBenefits())),
        iif(() => this.authenticationService.isLogin, this.mastheadService.getGlobalCartBenefits(), EMPTY))
        .pipe(
          mergeAll(),
          takeUntil(this.unsubscribe))
        .subscribe(data => this.loadCart(data));

      //refresh global cart
      //This is not needed now as there is only cart component placed, Keep this code here for reference
      /*
      this.globalojectService.reloadShoppingCart.pipe(
        filter((reloaded) => reloaded && this.authenticationService.isLogin),
        takeUntil(this.unsubscribe))
      .subscribe(() => this.mastheadService.refreshCart());
      */

      this.globalojectService.Products.subscribe(loadedProducts => {
        if (loadedProducts != null && loadedProducts.length > 0) {
          this.globalProducts = loadedProducts;
        }
      });

    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  private loadCart(data: EmployeeBenefitsType) {
    this.globalojectService.shoppingCartLoaded.next(data);
    this.quotes = [];
    // this.whosCovered = [];

    if (data?.employeeBenefitGroups?.length > 0) {
      this.totalCost_Today = data.totalCost_Today;
      for (let employeeBenefitGroupIndex = 0; employeeBenefitGroupIndex < data.employeeBenefitGroups.length; employeeBenefitGroupIndex++) {
        const employeeBenefitsByProducts = data.employeeBenefitGroups[employeeBenefitGroupIndex].employeeBenefitsByProducts;
        for (let benefitsByProductsIndex = 0; benefitsByProductsIndex < employeeBenefitsByProducts.length; benefitsByProductsIndex++) {
          const currentbenefitsByProducts = employeeBenefitsByProducts[benefitsByProductsIndex];
          if (currentbenefitsByProducts.employeeBenefitTypes != null && currentbenefitsByProducts.employeeBenefitTypes.length > 0) {
            for (let benefitsIndex = 0; benefitsIndex < currentbenefitsByProducts.employeeBenefitTypes.length; benefitsIndex++) {
              let currentBenefit = currentbenefitsByProducts.employeeBenefitTypes[benefitsIndex];
              this.currencyCode = currentBenefit.currencyCode;

              // this.whosCovered.push(this.getWhosCovered(currentBenefit));
              this.quotes.push(currentBenefit);
            }
          }
        }
      }
    }
    this.onCartUpdated.emit(this.quotes.length);
  }

  getWhosCovered(currentBenefit: any): string {
    let bPlanNameWhosCovered = '';
    let forStr = ' ' + this.resourceStrings['Aon.Cart.For'] + ' ';
    if (!currentBenefit.singleParticipantOnly && currentBenefit.coveredDependants && currentBenefit.coveredDependants.length > 0) {

      let youCovered = currentBenefit.coveredDependants.filter(depdnt => {
        return depdnt.lookupRelationshipType_RecordID == EmployeeRelationshipTypeNames.Employee;
      }).length > 0;
      let spouseCovered = currentBenefit.coveredDependants.filter(depdnt => {
        return depdnt.lookupRelationshipType_RecordID == EmployeeRelationshipTypeNames.Spouse;
      }).length > 0;
      let parentCovered = currentBenefit.coveredDependants.filter(depdnt => {
        return depdnt.lookupRelationshipType_RecordID == EmployeeRelationshipTypeNames.Other;
      }).length > 0;
      let childrenCovered = currentBenefit.coveredDependants.filter(depdnt => {
        return depdnt.lookupRelationshipType_RecordID == EmployeeRelationshipTypeNames.Child;
      }).length;
      let domesticPartnerCovered = currentBenefit.coveredDependants.filter(depdnt => {
        return depdnt.lookupRelationshipType_RecordID == EmployeeRelationshipTypeNames.DomesticPartner;
      }).length;

      if (youCovered && !spouseCovered && !parentCovered && childrenCovered == 0 && !domesticPartnerCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youCovered'];
      }
      if (youCovered && spouseCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youAndSpouse'];
      }
      if (youCovered && domesticPartnerCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youAndPartner'];
      }
      if (youCovered && childrenCovered == 1) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youAndChild'];
      }
      if (youCovered && childrenCovered > 1) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youAndChildren'];
      }
      if (youCovered && parentCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youAndParent'];
      }
      if (youCovered && spouseCovered && childrenCovered > 0) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youAndFamily'];
      }
      if (youCovered && spouseCovered && childrenCovered > 0 && parentCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youFamilyAndParent'];
      }
      if (youCovered && spouseCovered && parentCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youSpouseAndParent'];
      }
      if (youCovered && domesticPartnerCovered && childrenCovered == 1) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youPartnerAndChild'];
      }
      if (youCovered && domesticPartnerCovered && childrenCovered > 1) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youPartnerAndChildren'];
      }
      if (youCovered && parentCovered && childrenCovered == 1) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youParentAndChild'];
      }
      if (youCovered && parentCovered && childrenCovered > 1) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youParentAndChildren'];
      }
      if (youCovered && domesticPartnerCovered && parentCovered) {
        bPlanNameWhosCovered = forStr + this.resourceStrings['Aon.Cart.youPartnerAndParent'];
      }
    }
    else if (currentBenefit.singleParticipantOnly && currentBenefit.coveredDependants && currentBenefit.coveredDependants.length > 0) {
      bPlanNameWhosCovered = forStr + currentBenefit.coveredDependants[0].firstName + ' ' + currentBenefit.coveredDependants[0].lastName;
    }
    return bPlanNameWhosCovered;
  }

  goToQuote(curBenefit: any): void {
    let redirectUrl = this.baseUrl + "/quote/" + curBenefit.line_RecordID + "/details";
    if (curBenefit.entityInformationType.sableInstance_RecordID != null) {
      redirectUrl = redirectUrl + '/' + curBenefit.entityInformationType.sableInstance_RecordID;
    }

    this.globalProducts.forEach(singleProduct => {
      if (singleProduct.line_GUID == curBenefit.line_RecordID) {
        this.analyticsService.trackCustomEvent({
          "event": "shopping-cart-edit",
          "product-english-name": singleProduct.gaTag
        });
      }
    });

    this.hideCartPopUp.emit();
    this.router.navigate([redirectUrl]);
  }

  goToCheckout() {
    this.hideCartPopUp.emit();
    this.analyticsService.trackHeapEvent("PurchaseProcess Checkout ShoppingCart","Purchase Process","Checkout","Shopping Cart");
    this.router.navigate([this.baseUrl + '/benefit/checkout']);
  }

  confirmDelete(curBenefit: any, content) {
    this.currentEmployeeBenefitID = curBenefit.employeeBenefitID;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };

    this.globalProducts.forEach(singleProduct => {
      if (singleProduct.line_GUID == curBenefit.line_RecordID) {
        this.analyticsService.trackCustomEvent({
          "event": "shopping-cart-delete",
          "product-english-name": singleProduct.gaTag
        });
      }
    });

    this.modalService.open(content, ngbModalOptions);
  }

  deleteQuote(): void {
    this.modalService.dismissAll();
    this.ngxService.start();

    const q = this.quotes.find(x => x.employeeBenefitID === this.currentEmployeeBenefitID);

    this.mastheadService.deleteEmployeeSavedQuote(this.currentEmployeeBenefitID)
      .pipe(catchError(() => of({ didSave: false } as SaveResult)))
      .subscribe(result => {
        if (result?.didSave) {
          this.removeDeletedQuotes(this.currentEmployeeBenefitID);
          this.globalojectService.reloadShoppingCart.next(true);
          this.globalojectService.quoteDeleted.next(this.currentEmployeeBenefitID);

          //If the deleted purchase is now in SableFlow, we need navigate user back to home
          if (q?.line_RecordID && q?.entityInformationType?.sableInstance_RecordID) {
            const lineId = q.line_RecordID.toLowerCase();
            const sableInstanceId = q.entityInformationType.sableInstance_RecordID.toLowerCase();
            const routeUrl = this.router.url.toLowerCase();
            const sableFlowUrl = `quote/${lineId}/details/${sableInstanceId}`;
            if (routeUrl.indexOf(sableFlowUrl) >= 0) {
              this.goHome();
            }
          }
        }
        this.ngxService.stop();
      });
  }

  removeDeletedQuotes(currentEmployeeBenefitID) {
    if (this.quotes != null && this.quotes.length > 0) {
      this.quotes.forEach((data, index) => {
        if (data.employeeBenefitID == currentEmployeeBenefitID) {
          this.quotes.splice(index, 1);
        }
      })
    }
  }
}

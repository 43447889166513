import { Component, OnInit, Input, OnDestroy, Injector } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { takeUntil } from 'rxjs/operators';
import { GlobalObjectsService } from '../services/global-objects.service';

@Component({
  selector: 'app-plan-dependent-info',
  templateUrl: './plan-dependent-info.component.html'
})
export class PlanDependentInfoComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @Input() dependantsData: any;
  @Input() whosCoveredLabel = false;
  @Input() product: any = null;
  @Input() displayInBlock: boolean = false;
  currentProduct: any = null;
  modalOption: NgbModalOptions = {};
  uiConfig: UIConfigurationType;
  constructor(
    private readonly modalService: NgbModal,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly injector: Injector,
    private readonly globalObjectsService: GlobalObjectsService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Dependants.FutureExpire'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });

    this.globalObjectsService.Products.subscribe(r => {
      let products = r.filter(r => r?.line_GUID == this.product?.line_RecordID);
      if (products != null && products.length > 0) {
        this.currentProduct = products[0];
      }
    });
    this.filterEmployeeFromDependentsList(this.dependantsData);
  }


  filterEmployeeFromDependentsList(depList) {

    if (this.product != null && this.currentProduct != null && !this.product.showEmployeeAsCovered) {
      if (this.product.isMultiInstance === true) {
        return this.dependantsData;
      }
      this.dependantsData = depList.filter((item) => {
        return item.lookupRelationshipType_RecordID != 0;
      })
    }


    return this.dependantsData;
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
}

import { Component, OnInit, Inject, HostListener, Injector, OnDestroy, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { GlobalObjectsService } from '../..//shared/services/global-objects.service';
import { CommonService } from '../../core/services';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/shared/models/product.model';
import { JwtHelper } from '../guards/jwt.helper';
import { Router } from '@angular/router';
import { BootstrapRequestContext } from 'tbs-typings';
import { NotificationService } from '../services/notification.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { of, combineLatestWith } from 'rxjs';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { BenefitSaveAlertComponent } from '../../modules/benefit/benefit-save-alert/benefit-save-alert.component';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { Constants, SessionStorageKey } from 'src/app/shared/models/constants';
import { CategoryType } from 'src/app/shared/models/categoryType.model';
import { SavedQuoteInfoComponent } from 'src/app/modules/home/saved-quote-info/saved-quote-info.component';
import { ProfileService } from 'src/app/modules/profile/profile.service';
import { ProductService } from 'src/app/modules/product/product.service';
@Component({
  selector: 'app-masthead',
  templateUrl: './masthead.component.html'
})
export class MastheadComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @ViewChild('noEligibleProductsWarningContent') noEligibleProductsWarningContent: any;
  closeResult: string;
  faLifeRing: any;
  faUser: any;
  isNavbarCollapsed = true;
  products: Product[];
  categories: CategoryType[];
  preferredName: string;
  changeIcon1: boolean;
  changeIcon2: boolean;
  resourcestrings = {};
  selectedProductPagePath: string;
  selectedProductPageGUID: string;
  selectedProductEnglishName: string;
  checkSavedQuotesBeforeLaunchingQuote: boolean;
  selectedDocumentName: string;
  launchQuoteFromHomePage: boolean;
  campaignKey: string;
  headMenuObject: any;
  TID: string;
  productsCount: number = 0;
  hideLanguageSelection: boolean = false;
  mobileMaxWidth = 576; //Max scree size for mobile
  isMobile = false;
  el_overlay: any;
  isFailedPayment: boolean;
  uiConfig: UIConfigurationType;
  showInbox: boolean;
  isFromMobileApp: boolean;
  unreadMessage: number;
  hasGracePeriod: boolean;
  closeBenefit: boolean;
  isCategorizationEnabled: boolean = false;
  currentComponentName = 'masthead';
  isImpersonation: string;
  showSplashPageMenu: boolean = false;
  showMegaMenu: boolean = true;
  hideBenefitMenu: boolean = false;
  dropDownAutoClose: boolean | string = true;
  actAsDelegate: boolean = false;
  hasDelegateForEmployees: boolean = false;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  constructor(
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly injector: Injector,
    public readonly authenticationService: AuthenticationService,
    public readonly commonService: CommonService,
    private readonly productService: ProductService,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly modalService: NgbModal,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly paymentService: PaymentService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly profileService: ProfileService
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
    this.addResourceStringKeys(this.resService.mastheadStringKeys);

    this.globalObjectsService.Products
      .pipe(combineLatestWith(
        this.globalObjectsService.CategorizationEnabled,
        this.globalObjectsService.Categories
      ), takeUntil(this.unsubscribe))
      .subscribe(([r, isCategorizationEnabled, Categories]) => {
        this.products = r;
        this.isCategorizationEnabled = isCategorizationEnabled;
        this.categories = Categories;
        this.initBenefitsList();
      });

    this.changeIcon1 = false;
    this.changeIcon2 = false;
    this.headMenuObject = JSON.parse(bootstrapRequestContext.mastheadMenu);
  }

  toggleNavBar() {
    this.helperService.narBarCollapseState.next(!this.isNavbarCollapsed)
  }

  ngOnInit(): void {
    let isCurrentUserSubscribed = false;
    this.globalObjectsService.checkHeaderFooterConfig.subscribe(value => {
      if (value) {
        this.helperService.narBarCollapseState.subscribe((isCollapsed) => {
          this.isNavbarCollapsed = isCollapsed;
        });
        this.campaignKey = this.helperService.getCampaignKey();
        this.uiConfig = this.uiconfigrationService.getUIConfiguration();
        this.isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';
        // Hide Inbox in member site for mobile app
        this.showInbox = this.uiConfig.uiConfiguration.enableInbox && !this.isFromMobileApp;
        this.hideBenefitMenu = this.uiConfig.uiConfiguration.hideBenefitMenu;
        this.getCurrentPathRoleSettingStatus(this.injector);
        this.loadResourceStringAsObservable()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => this.getResouceStringsAsObject(res));
        //currentUser does not unsubscribe as masthead component is always available and ngdestroyed not triggered 
        //because of this globalcartbenefits api called many times
        //So flag check for prevent current user subsription multiple times
        if (!isCurrentUserSubscribed) {
          this.authenticationService.currentUser
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((user) => {
              isCurrentUserSubscribed = true;
              if (user?.id_token) {
                const jwtHelper = new JwtHelper();
                const decodedUser = jwtHelper.decodeToken(user.id_token);

                if (this.authenticationService.isLogin) {
                  this.showMegaMenu = true;
                }
                this.updateAnalyticsValues(decodedUser);
                this.globalObjectsService.reloadShoppingCart.next(true);
                this.globalObjectsService.reloadProfileGracePeriod.next(true);
              }
            });
        }
        this.globalObjectsService.compactEmployeeInformation
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(emp => {
            if (this.authenticationService.isLogin) {
              this.preferredName = emp?.preferredName;
            }
          });

        //checkHeaderFooterConfig only be reset after user login or logout no need to further watch currentUser
        const parentPath = '/Categories';
        if (!this.commonService.logoutInProgress && (this.bootstrapRequestContext.allowAnonymousAccess || this.authenticationService.isLogin)) {
          this.commonService.getCategories(parentPath)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
              this.updateCat(data);
            });
        }

        this.getScreenView();

        this.notificationService.getMessage()
          .pipe(
            mergeMap(() => this.loadResourceStringAsObservable()),
            takeUntil(this.unsubscribe)
          )
          .subscribe(res => this.getResouceStringsAsObject(res));

        //Payment Status Checking should be called for VB sites post login
        this.checkPaymentStatus();
        //Get EmployeeMEssageCount post-Auth only if it's enabled, checkHeaderFooterConfig only be reset after user login or logout no need to further watch currentUser
        this.getMessageCount();

      }
      if (this.helperService.isLoggedOn()) {
        const jwtHelper = new JwtHelper();
        const user = JSON.parse(sessionStorage.getItem('currentUser'));
        this.isImpersonation = jwtHelper.decodeToken(user.id_token).IsImpersonation;
      }
      if (this.uiConfig.uiConfiguration.showSplashPage && this.isImpersonation == 'False') {
        this.showSplashPageMenu = true;
      }
      else {
        this.showSplashPageMenu = false;
      }
      this.getCompactEmployeeInfo();
    });

    this.commonService.resetLanguageList.subscribe(rl => {
      //show site whole list after logout
      if (rl
        && this.hideLanguageSelection
        && !this.helperService.isLoggedOn()
        && sessionStorage.getItem('AllowedLanguages')
        && JSON.parse(sessionStorage.getItem('AllowedLanguages')).allowedLanguages.length > 1
      ) {
        this.hideLanguageSelection = false;
      }
    });

    if (this.bootstrapRequestContext.enableArticlePreAuthentication && this.helperService.isLoggedOn()) {
      this.commonService.resetOpenAccessDetails.next(true);
    }
  }

  getMessageCount() {
    if (this.showInbox && this.authenticationService.isLogin) {
      this.commonService.GetEmployeeMessageCount()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(data => {
          this.unreadMessage = data.unreadCount;
          if (this.unreadMessage != 0) {
            this.commonService.unreadCount = true;
          }
          else {
            this.commonService.unreadCount = false;
          }
        });
    }
  }

  checkPaymentStatus() {
    if (this.bootstrapRequestContext.showVBBanners) {
      this.authenticationService.currentUser
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(user => {
          if (this.authenticationService.isLogin) {
            this.paymentService.checkEmployeePaymentStatus()
              .pipe(catchError(() => of(false)), takeUntil(this.unsubscribe))
              .subscribe(hasFailedPayment => {
                this.isFailedPayment = hasFailedPayment;
                this.globalObjectsService.failedPaymentSubject.next(hasFailedPayment);
              });
          }
        });
    }
  }

  getCompactEmployeeInfo() {
    if (this.authenticationService.isLogin) {
      this.globalObjectsService.compactEmployeeInformation.subscribe(data => {
        if (this.authenticationService.isLogin && data == null) {
          this.commonService.getCompactEmployeeInformation()
            .pipe(take(1))
            .subscribe(data => {
              this.globalObjectsService.compactEmployeeInformation.next(data);
            })
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
    this.showMegaMenu = false;
    this.closeModelAddOverlay(false);
  }

  updateCat(data) {

    this.getAdditionalProductData(data);

    this.globalObjectsService.Categories.next(this.commonService.getDefinedCategories(data));
    this.globalObjectsService.CategorizationEnabled.next(this.commonService.isCategorizationEnabled(data));
    this.globalObjectsService.Products.pipe(take(1))
      .subscribe(products => {
        let hasProducts = this.products && this.products.length > 0;
        if (this.bootstrapRequestContext.blockUserIfNoProductsAvailable && this.authenticationService.isLogin && !hasProducts) {
          //No available products
          let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title'
          };
          this.modalService.open(this.noEligibleProductsWarningContent, ngbModalOptions);
        }
      });
  }

  getAdditionalProductData(data) {
    let languageCode = this.helperService.getCurrentLanguageCode().cultureCode;
    let productsList = this.commonService.getProductsFromVirtualCategory(data);
    let productIdsList = [];
    (productsList || []).forEach((item: Product) => {
      productIdsList.push({ productId: item.line_GUID, nodeAliasPath: item.nodeAliasPath });
    });
    this.productService.getAdditionalProductData(languageCode, productIdsList)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(productAdditionalDate => {

        (productsList || []).forEach((item: Product) => {
          let match = productAdditionalDate.find(product => product.productId == item.line_GUID);
          item.hasCoverageDetailsPage = match?.hasCoverageDetails ?? false;
        });

        this.globalObjectsService.Products.next(productsList);
      });
  }

  ngOnChanges(): void {
    const parentPath = '/Categories';
    this.commonService.getCategories(parentPath)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.updateCat(data);
      });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logOut(): void {
    let urL = this.router.url.valueOf();
    if (!urL.includes(this.baseUrl + "/quote") || !this.uiConfig.uiConfiguration.showAlertPopup || (urL.includes("/complete"))) {
      this.authenticationService.logout().subscribe(() => {
        this.helperService.logoutRedirection();
      });
    } else if (this.uiConfig.uiConfiguration.showAlertPopup) {
      this.helperService.logoutRedirection();
    }
  }

  setActiveProduct(path: string, guid: string, productName: string, launchQuote: boolean, productEnglishName: string, checkSavedQuotesBeforeLaunchingQuote: boolean, isEnterPress = false): void {
    this.selectedProductPagePath = path;
    this.selectedProductPageGUID = guid;
    this.selectedDocumentName = productName;
    this.launchQuoteFromHomePage = launchQuote;
    this.selectedProductEnglishName = productEnglishName;
    this.checkSavedQuotesBeforeLaunchingQuote = checkSavedQuotesBeforeLaunchingQuote;
    if (isEnterPress) {
      this.goToProduct();
    }
  }

  goToProduct(): void {
    this.modalService.dismissAll();
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
    if (this.uiConfig.uiConfiguration.showAlertPopup && this.router.url.includes("/quote") && !this.router.url.includes(this.selectedProductPageGUID)) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        ariaLabelledBy: 'leave-flow-alert-modal-title'
      };
      const modalRef = this.modalService.open(BenefitSaveAlertComponent, ngbModalOptions);
      modalRef.result.then((result) => {
        if (result) {
          this.proceedToNextProduct();
        }
      }, (reason) => {
        /*Leave empty or handle reject*/
        /*This is used to avoid the JS error when calling dismiss to close a modal*/
      });
    } else {
      this.proceedToNextProduct();
    }
    this.analyticsService.trackCustomEvent({ 'event': 'top-nav-quote', 'product-english-name': this.selectedProductEnglishName });
  }

  proceedToNextProduct() {
    if (!this.launchQuoteFromHomePage) {
      const redirectUrl = this.baseUrl + '/product/' + this.selectedProductPageGUID;
      this.router.navigate([redirectUrl]);
    } else {
      if (this.checkSavedQuotesBeforeLaunchingQuote) {
        this.profileService.checkEmployeeSavedQuotesExists(this.selectedProductPageGUID)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(response => {
            if (response) {
              const ngbModalOptions: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false,
                ariaLabelledBy: 'leave-flow-alert-modal-title'
              };
              ngbModalOptions.container = '#app-modal-anchor';

              this.modalService.open(SavedQuoteInfoComponent, ngbModalOptions).result.then((result) => {
                if (result) {
                  const redirectUrl = this.baseUrl + '/profile';
                  this.router.navigate([redirectUrl], { fragment: 'savedquote' });
                }
                else {
                  this.gotoQuote();
                }
              }, (reason) => {
              });
            }
            else {
              this.gotoQuote();
            }
          }
          );
      }
      else {
        this.gotoQuote();
      }
    }
  }

  gotoQuote() {
    const redirectUrl = this.baseUrl + '/quote/' + this.selectedProductPageGUID + '/details';
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([redirectUrl]);
  }

  public benefits: Array<{
    categoryName: string,
    icon: string,
    icon2: string,
    groupBenefits: Product[];
  }> = [];
  private initBenefitsList() {
    if (this.products) {
      let categories = [];
      this.benefits = [];
      this.products.forEach(product => {
        if (product.category) {
          if (!categories.includes(product.category)) {
            categories.push(product.category);
            let gbs = [];
            this.benefits.push({
              categoryName: product.category,
              icon: product.icon,
              icon2: product.icon2,
              groupBenefits: gbs
            });
          }
          let findGroup = this.benefits.findIndex(b => b.categoryName == product.category);
          this.benefits[findGroup].groupBenefits.push(product);
        }
      });
    }
  }

  get ShowMastHeadBenefitsMenu(): boolean {
    return this.benefits && this.benefits?.length > 0 && !this.hideBenefitMenu && (this.headMenuObject.benefits && this.showAfterLoginCheck());
  }

  showAfterLoginCheck(): boolean {
    if (!this.helperService.AllowAnonymousAccess() && !this.authenticationService.isLogin) {
      return false;
    }
    return true;
  }

  get showArticleWrapperDetails(): boolean {
    if (this.bootstrapRequestContext.enableArticlePreAuthentication || !this.bootstrapRequestContext.enableArticlePreAuthentication && this.showAfterLoginCheck()) {
      return true;
    }
    return false;
  }

  onCartUpdated(productsCount: number): void {
    this.productsCount = productsCount;
  }

  onAllowedLanguagesLoaded(hideLanguageSelection: boolean): void {
    this.hideLanguageSelection = hideLanguageSelection;
  }

  onGracePeriodUpdated(hasGracePeriod: boolean): void {
    this.hasGracePeriod = hasGracePeriod;
  }

  isAsiaVB(): boolean {
    return this.helperService.IsVBAsia();
  }

  isUsVB(): boolean {
    return this.helperService.IsUSVB();
  }

  isOlb(): boolean {
    return this.helperService.IsOLB();
  }

  closemodalcross(statusofclick: boolean) {
    this.isNavbarCollapsed = statusofclick;
  }

  truncate(input) {
    if (input && input.length > 10) {
      return input.substring(0, 10) + '...';
    }
    return input;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenView();
  }

  getScreenView() {
    this.isMobile = window?.innerWidth < this.mobileMaxWidth;
  }

  closeModelAddOverlay(event) {
    if (!event) {
      if (this.headMenuObject.benefits) {
        this.closeBenefit = true;
      }
      if (document.querySelector('.bg-overlay')) {
        document.body.removeChild(document.querySelector('.bg-overlay'));
      }
    } else {
      if (this.headMenuObject.benefits) {
        this.closeBenefit = false;
      }
      this.el_overlay = document.createElement('span');
      this.el_overlay.className = 'bg-overlay';
      document.body.appendChild(this.el_overlay);
    }
  }

  clickedDropDown: NgbDropdown;
  closeOtherDropdowns(clickedDropdown: NgbDropdown) {
    this.clickedDropDown = clickedDropdown;
    setTimeout(() => {
      let totalPlanCon = document.querySelectorAll<HTMLElement>(".qContent").length;
      for (let i = 0; i < totalPlanCon; i++) {
        let offsetHeightCn = document.querySelectorAll<HTMLElement>(".qContent")[i].offsetHeight;
        let scrollHeightCn = document.querySelectorAll<HTMLElement>(".qContent")[i].scrollHeight;
        if (scrollHeightCn == offsetHeightCn) {
          document.querySelectorAll<HTMLElement>('.qContent')[i].classList.add("disableTooltip");
        }

      }
    }, 100);

    this.dropdowns.toArray().forEach(el => {
      if (el != clickedDropdown)
        el.close();
    });

    this.keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown);
  }

  /**
   * To handle the custom browser scroll event.
   * Nav drop down getting close when the user clicks on the scroll bar
   * So, Adding some custom events to track the movement and making it open
   */
  keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown: NgbDropdown): void {
    let scrollEventFired: boolean = false;
    try {
      const self = this;
      const eventHandlers = {
        scroll() {
          self.dropDownAutoClose = false;
          scrollEventFired = true;
        },
        mousedown() {
          // 3 Step
          document.addEventListener("scroll", eventHandlers.scroll);
          self.dropDownAutoClose = true;
          scrollEventFired = false;
        },
        mouseup() {
          self.dropDownAutoClose = true;
          if (!scrollEventFired) {
            eventHandlers._clear();
            setTimeout(() => {
              if (clickedDropdown.isOpen()) {
                self.keepTheDropDownNavBarOpenWhenScrolling(clickedDropdown);
              }
            }, 10);
          }
        },
        _clear() {
          document.removeEventListener("mousedown", eventHandlers.mousedown);
          document.removeEventListener("scroll", eventHandlers.scroll);
          document.removeEventListener("mouseup", eventHandlers.mouseup);
        }
      }

      // 1 Step
      document.addEventListener("mousedown", eventHandlers.mousedown);
      // 2 Step
      document.addEventListener("mouseup", eventHandlers.mouseup);
    } catch { }
  }

  checkMegaMenuHasContent(event) {
    this.showMegaMenu = event;
  }

  closeOurValues(event, dropdwon) {
    if (event)
      dropdwon.close();
    else
      dropdwon.open();
  }

  updateAnalyticsValues(decodedUser: any) {

    let isLogoutUser = !this.authenticationService.isLogin && this.analyticsService.verifyDataLayerValue('IsAuthenticated', 'true');

    if (isLogoutUser) {
      this.TID = this.analyticsService.getDataLayerValue('TID');
    } else {
      this.TID = decodedUser.TID;
    }

    if (this.TID == Constants.GuidEmpty && this.helperService.getUrlParameter('TID')) {
      this.TID = this.helperService.getUrlParameter('TID');
    }

    let externalTID = this.helperService.getUrlParameter('external_TID');
    if (!externalTID) {
      externalTID = Constants.GuidEmpty;
    }

    let user_info_params = {
      "event": "userinfo-access",
      "IsAuthenticated": this.TID && this.TID != Constants.GuidEmpty,
      "PreferredCulture": decodedUser.PreferredCulture,
      "ClientCode": decodedUser.ClientCode,
    };

    if (decodedUser.IsSso && decodedUser.IsSso != "False") {
      this.analyticsService.updateDataLayer("IsSSO", decodedUser.IsSso);
    }

    let user_additional_info = {};

    user_info_params = {
      ...user_info_params,
    }

    if (this.TID != Constants.GuidEmpty) {
      user_additional_info = {
        "TID": this.TID,
        "externalTID": externalTID,
        "IsImpersonation": decodedUser.IsImpersonation == "True",
        "Ghost": decodedUser.Ghost == "True",
        "RegionalAffliationCode": decodedUser.RegionalAffliationCode ? decodedUser.RegionalAffliationCode : ""
      }
    } else if (this.analyticsService.getDataLayerValue('TID') != '' && this.analyticsService.getDataLayerValue('TID') != Constants.GuidEmpty) {
      user_additional_info = {
        "Loggedout": true
      }
    }

    let utm_params = this.helperService.getUTMParameters();
    let merged_params = { ...user_info_params, ...user_additional_info, ...utm_params };
    if (sessionStorage['externalUserIdentifier']) {
      let externalUserIdParams = {
        "externalUserIdentifier": sessionStorage['externalUserIdentifier']
      }
      merged_params = { ...merged_params, ...externalUserIdParams };
      sessionStorage.removeItem('externalUserIdentifier');
    }
    this.analyticsService.trackCustomEvent(merged_params);
    this.analyticsService.identifyUser(this.TID);
    if (this.TID && this.TID != Constants.GuidEmpty) {
      this.analyticsService.addUserProperties(merged_params);
    }
  }

  navClicked(navName: string = null) {
    this.isNavbarCollapsed = true;
    if (navName) {
      this.analyticsService.trackHeapEvent("Nav Bar Menu Clicked", "Site", "Nav Bar Menu Click", navName);
    }
  }

}

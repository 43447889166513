import { Injectable, Inject } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SableFlowDataService } from '@aon/sable-flow';
import { BootstrapRequestContext } from 'tbs-typings';
import { GlobalObjectsService } from '../services/global-objects.service';
import { AnalyticsService } from '../services/analytics.service';
import { HelperService } from '../services/helper.service';
import { SessionStorageKey } from 'src/app/shared/models/constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class AppFlowDataService implements SableFlowDataService {
  private readonly apiBaseUrl: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly globalojectService: GlobalObjectsService,
    private readonly analyticsService: AnalyticsService,
    private readonly helperService: HelperService,
    private readonly ngxService: NgxUiLoaderService
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }


  getSableContext(flowId: string) {
    if (sessionStorage.getItem('activeSableContext')) {
      const sableContext = JSON.parse(sessionStorage.getItem('activeSableContext'));
      sableContext.sableActivity_RecordID = null;
      let sableContextString: string = JSON.stringify(sableContext);
      return of(sableContextString);
    }
    else {
      let sableInstaceId = this.globalojectService.sableInstance_RecordID;
      if (sableInstaceId == 'null') {
        sableInstaceId = null;
      }
      const sableContext = {
        Sable_RecordID: flowId,
        SableInstance_RecordID: sableInstaceId,
        SableActivity_RecordID: null
      };
      let sableContextString: string = JSON.stringify(sableContext);
      return of(sableContextString);
    }
  }

  processFlow(sableContext: string, saveData: string, additionalContextData: any, goToInstruction?: any) {

    const context = JSON.parse(sableContext);
    this.analyticsService.recordPageView({
      event: 'sable-navigtion',
      navigationStart: Date.now(),
      pageViewLogged: false,
      flowError: null
    });

    if (goToInstruction?.responseType == 3 && sessionStorage.getItem(SessionStorageKey.isSableProcessFirstStep)) {
      sessionStorage.removeItem(SessionStorageKey.isSableProcessFirstStep);
      this.helperService.routeToReferrer(SessionStorageKey.ReferrerPathForTheQuoteFlow);
      this.ngxService.stop();
      return;
    }

    if (context.sableInstance_RecordID != null) {
      this.globalojectService.sableInstance_RecordID = context.sableInstance_RecordID;
    }

    let url = this.apiBaseUrl + 'sableprocess';
    if (saveData == '') {
      saveData = null;
    }

    let GoToInstruction = goToInstruction ?? this.globalojectService.goToInstruction;
    // for event flow
    if (sessionStorage.getItem('activeEventFlowId')) {
      let SableInstance_RecordID = '';
      if (additionalContextData['parent_SableInstance_RecordID']) {
        SableInstance_RecordID = additionalContextData['parent_SableInstance_RecordID'];
        let currentSableContext = JSON.parse(sableContext);
        let parentSableContext = {
          SableActivity_RecordID: currentSableContext.SableActivity_RecordID ?? currentSableContext.sableActivity_RecordID,
          SableInstance_RecordID: currentSableContext.SableInstance_RecordID ?? currentSableContext.sableInstance_RecordID,
          Sable_RecordID: currentSableContext.Sable_RecordID !== undefined ? currentSableContext.Sable_RecordID : sessionStorage.getItem('activeEventFlowId')
        }
        sableContext = JSON.stringify(parentSableContext);
      }
      else if (Object.prototype.hasOwnProperty.call(additionalContextData, 'sub_SableFlowInstance_RecordID')) {
        //change button
        if (additionalContextData['sub_SableFlowInstance_RecordID']) {
          SableInstance_RecordID = additionalContextData['sub_SableFlowInstance_RecordID'];
        }
      } else {
        //from continue enrollment button
        if (context.sableInstance_RecordID != null) {
          SableInstance_RecordID = context.sableInstance_RecordID;
        } else if (context.SableInstance_RecordID != null) {
          SableInstance_RecordID = context.SableInstance_RecordID;
        }
      }

      if (SableInstance_RecordID && !goToInstruction?.fromEndEntityAdapter) {
        if (!GoToInstruction) {
          GoToInstruction = { "SableInstance_RecordID": SableInstance_RecordID };
        } else if (!GoToInstruction['SableInstance_RecordID'] && !GoToInstruction['sableInstance_RecordID']) {
          GoToInstruction['SableInstance_RecordID'] = SableInstance_RecordID;
        }
      }
    }
    if (GoToInstruction?.fromEndEntityAdapter) {
      delete GoToInstruction['fromEndEntityAdapter'];
    }
    if (sessionStorage.getItem(SessionStorageKey.InTwoWaySSOProcess) == 'true') {
      additionalContextData['InteractionIncomplete'] = sessionStorage.getItem(SessionStorageKey.SsoInteractionIncomplete);
    }
    try {
      if (saveData != null) {
        if (Object.keys(saveData).length && !GoToInstruction?.KEEP_GOTO) {
          GoToInstruction = null;
        }
      }
      if (GoToInstruction?.KEEP_GOTO) {
        delete GoToInstruction.KEEP_GOTO;
      }
    } catch (e) { }
    //using 'any' type to support System.Text.Json at server side
    const curSelDelegateEmployee = sessionStorage.getItem(SessionStorageKey.ActDelegateEmployee);
    let data: any = {
      SableContext: JSON.parse(sableContext),
      SaveData: saveData, AdditionalContextData: additionalContextData,
      GoToInstruction: GoToInstruction,
      ActForEmployeeID: curSelDelegateEmployee == null ? "" : curSelDelegateEmployee
    };

    return this.httpClient.post<any>(url, data)
    .pipe(
      map(data => {
        this.globalojectService.sableInstance_RecordID = data.sableContext.sableInstance_RecordID;
        this.globalojectService.goToInstruction = null;
        this.globalojectService.newEntityInstance = data.sableContext.newEntityInstance ? data.sableContext.newEntityInstance : null;

          try {
            if (data.liveActivity.isFirstStep) {
              sessionStorage.setItem(SessionStorageKey.isSableProcessFirstStep, 'true');
            } else {
              sessionStorage.removeItem(SessionStorageKey.isSableProcessFirstStep);
            }
          } catch { }

          try {
            let sableFlowDivider: any = document.getElementsByClassName('sable-flow-divider')[0];
            if (sableFlowDivider.style.display != 'block') {
              sableFlowDivider.style.display = 'block';
            }
          } catch { }

        try {
          if (data.liveActivity.componentAdapterName == 'ErrorActivity') {
            this.analyticsService.recordPageView({
              event: 'sable-navigtion-result',
              flowError: data.liveActivity.activityData.Error
            });
          }
        } catch { }

          return data;
        }),
        tap(sideData => this.analyticsService.trackSableStep(sideData)), // track sable process in Google Analytics
      );
  }
  finalActivity() {
    console.log("thank You");
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { Product } from '../../shared/models/product.model';
import { ContentItemModel } from '../../shared/models/contentItem.model';
import { HttpCacheService, HttpRequestCache, IHttpCacheOptions } from '../cache/http-request-cache';
import { Subject, BehaviorSubject } from 'rxjs';
import { EmployeeMessage, EmployeeMessageCount } from '../../shared/models/employeeMessage.model';
import { BenefitProduct } from '../../shared/models/BenefitProduct.model';
import { CategoryType } from 'src/app/shared/models/categoryType.model';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/shared/services/helper.service';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { JackalEngine } from '@aon/jackal';
import { SessionStorageKey } from '../../shared/models/constants';
import { LanguageCulture } from '../../shared/models/languageCulture.model';
import { CommonPageModel } from 'src/app/shared/models/commonPage.model';
import { CompactEmployeeInformationModel } from '../../modules/profile/models/compactEmployeeInformation.model';
import { SaveAddOn } from 'src/app/shared/models/saveElection.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private readonly refreshSubject = new Subject();
  public showChatUnavailable: BehaviorSubject<boolean>;
  public resetClientBrandStyle: Subject<boolean>;
  public resetSelectedLanguage = new Subject<boolean>();
  public resetLanguageList = new Subject<boolean>();
  public logoutAndGuestTokenBack = new Subject<boolean>();
  public resetOpenAccessDetails = new Subject<boolean>();
  public ClaimLanguageChange: BehaviorSubject<boolean>;

  deferralCoverLabel: string;
  deferralInfoText: string;
  productDetailsFetched: boolean = false;
  apiBaseUrl: string;
  baseUrl: string;
  tenantFolderName: string;
  getAllowedLanguagesUrl: string;
  siteName: string;
  unreadCount: boolean;
  logoutInProgress: boolean = false;
  appInitInProgress: boolean = false;
  loginInProgress: boolean = false;
  jackalEngine = new JackalEngine();
  addOnForSave: SaveAddOn[] | null = null;

  constructor(
    private readonly  helperService: HelperService,
    private readonly  router: Router,
    private readonly  http: HttpClient,
    private readonly  httpCache: HttpCacheService,
    private readonly  globalObjectsService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.baseUrl = this.helperService.getBaseUrl();
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.getAllowedLanguagesUrl = this.apiBaseUrl + 'allowedlanguages';
    this.siteName = bootstrapRequestContext.siteName;
    this.tenantFolderName = bootstrapRequestContext.tenantFolderName;
    this.showChatUnavailable = new BehaviorSubject<boolean>(false);
    this.resetClientBrandStyle = new Subject<boolean>();
    this.resetSelectedLanguage = new BehaviorSubject<boolean>(false);
    this.resetLanguageList = new BehaviorSubject<boolean>(false);
    this.logoutAndGuestTokenBack = new BehaviorSubject<boolean>(false);
    this.resetOpenAccessDetails = new BehaviorSubject<boolean>(false);
    this.ClaimLanguageChange = new BehaviorSubject<boolean>(false);

  }

  getAllowedLanguages() {
    return this.http.get<any>(this.getAllowedLanguagesUrl, { params: { 'siteName': this.siteName } });
  }

  getResourceStrings(languageCode: string, keys: string[]) {
    let requestUrl = this.apiBaseUrl + languageCode + '/uiresourcestrings';
    return this.http.post<any>(requestUrl, keys);
  }

  getRegistrationForm(registrationFormId: string) {
    let requestUrl = this.apiBaseUrl + 'registrationform/' + registrationFormId;

    return this.http.get<any>(requestUrl);
  }


  getMenus(currentCulture: string) {
    let getMenuUrl = this.apiBaseUrl + currentCulture + '/menuitems';
    return this.http.get<any>(getMenuUrl);
  }

  getUIConfiguration() {
    let requestUrl = this.apiBaseUrl + this.siteName + '/uiconfiguration';
    return this.http.get<any>(requestUrl);
  }
  getStyleSheet() {
    let requestUrl = this.apiBaseUrl + this.siteName + '/stylesheet';
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      }),
      responseType: 'text'
    }
    return this.http.get<string>(requestUrl, httpOptions);
  }
  getRoleStyleSheets() {
    let requestUrl = this.apiBaseUrl + this.siteName + '/rolestylesheet';
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      }),
      responseType: 'text'
    }
    return this.http.get<string>(requestUrl, httpOptions);
  }
  public getProducts(parentPath: string) {
    let productsUrl = this.apiBaseUrl + 'products?parentPath=' + parentPath;
    return this.http.get<Product[]>(productsUrl);
  }

  public getCategories(parentPath: string) {
    let productsUrl = this.apiBaseUrl + 'categories?parentPath=' + parentPath;
    return this.http.get<CategoryType[]>(productsUrl);
  }

  public getDefinedCategories(categories: CategoryType[]) {
    if (categories && categories.length > 0) {
      return categories.filter((x) => !x.isVirtual);
    }

    return [];
  }

  public getProductsFromVirtualCategory(categories: CategoryType[]): Product[] {
    if (categories && categories.length > 0) {
      let vc = categories.find((x) => x.isVirtual);
      if (vc?.products) {
        return vc.products;
      }
    }

    return [];
  }

  public isCategorizationEnabled(categories: CategoryType[]): boolean {
    if (categories && categories.length > 0) {
      let vc = categories.find((x) => x.isVirtual);
      if (!vc || categories.length > 1) {
        return true;
      }
    }

    return false;
  }

  public getBenefitProducts() {
    let productsUrl = this.apiBaseUrl + 'Benefit/Products';
    return this.http.get<BenefitProduct[]>(productsUrl);
  }

  sendCode() {
    let requestUrl = this.apiBaseUrl + this.siteName + '/sendcode';
    return this.http.get<any>(requestUrl);
  }

  verifyCode(code: string) {
    let requestUrl = this.apiBaseUrl + this.siteName + '/verifycode' + '/' + code;
    return this.http.get<any>(requestUrl);
  }

  getLogo() {
    let requestUrl = this.apiBaseUrl + 'logo';
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      }),
      responseType: 'text'
    }
    return this.http.get<string>(requestUrl, httpOptions);
  }

  getLoginImage() {
    let requestUrl = this.apiBaseUrl + 'loginImage';
    let httpOptions: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      }),
      responseType: 'text'
    }
    return this.http.get<string>(requestUrl, httpOptions);
  }

  registrationValidate(registartionFormData) {
    let requestUrl = this.apiBaseUrl + 'registrationvalidate';
    return this.http.post<any>(requestUrl, registartionFormData);
  }

  registrationSendPassword(registartionFormData) {
    let requestUrl = this.apiBaseUrl + 'registrationsendpassword';
    return this.http.post<any>(requestUrl, registartionFormData);
  }

  registrationValidatePassword(registartionFormData) {
    let requestUrl = this.apiBaseUrl + 'registrationvalidatepassword';
    return this.http.post<any>(requestUrl, registartionFormData);
  }

  registrationCreateAccount(registartionFormData) {
    let requestUrl = this.apiBaseUrl + 'registrationcreateaccount';
    let params = new HttpParams();
    params = params.append('grant_type', 'MfaVerify');
    params = params.append('registartionFormData', JSON.stringify(registartionFormData));
    return this.http.post<any>(requestUrl, params.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });
  }
  getLegalContent(category: string) {
    let requestUrl = this.apiBaseUrl + 'GetLegalContent';
    return this.http.post<any>(requestUrl, { Catetory: category });
  }

  @HttpRequestCache<CommonService>(function () {
    return {
      storage: this.httpCache,
      refreshSubject: this.refreshSubject
    } as IHttpCacheOptions;
  })
  public getContentItem(contentKey: string, subFolder: string = null) {
    let contentItemUrl = this.apiBaseUrl + 'contentitem';
    let nodePath = this.tenantFolderName;
    if (subFolder != null) {
      nodePath = nodePath + subFolder;
    }
    return this.http.post<ContentItemModel>(contentItemUrl, { Path: nodePath, ContentKeys: [contentKey] });
  }
  public getContentItemByPath(nodePath: string) {
    let contentItemUrl = this.apiBaseUrl + 'contentitemByPath';
    
    return this.http.post<ContentItemModel>(contentItemUrl, { Path: nodePath });
  }
  getContentItems(contentItemKeys: string[], subFolder: string = null) {
    const carrierItemsUrl = this.apiBaseUrl + 'contentItems';
    let nodePath = this.tenantFolderName;
    if (subFolder != null) {
      nodePath = nodePath + subFolder;
    }
    return this.http.post<ContentItemModel[]>(carrierItemsUrl, { Path: nodePath, ContentKeys: contentItemKeys });
  }

  @HttpRequestCache<CommonService>(function () {
    return {
      storage: this.httpCache,
      refreshSubject: this.refreshSubject
    } as IHttpCacheOptions;
  })
  public getContentItemForProduct(productId: string, contentKey: string, subFolder: string = null) {
    let contentItemUrl = this.apiBaseUrl + 'contentitem/' + productId;
    let nodePath = this.tenantFolderName;
    if (subFolder != null) {
      nodePath = nodePath + subFolder;
    }
    return this.http.post<ContentItemModel>(contentItemUrl, { Path: nodePath, ContentKeys: [contentKey] });
  }

  public getCommonPageWithKey(currentCulture: string, path: string, contentKey: string, checkOpenAccess: boolean = false) {
    let commonPageUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/commonpagewithkey';
    let nodePath = this.tenantFolderName + path;
    return this.http.post<CommonPageModel[]>(commonPageUrl, { Path: nodePath, ContentKeys: [contentKey], CheckOpenAccess: checkOpenAccess });
  }

  public getProductFiles(currentCulture: string, productPath: string, subFolder: string = null) {
    let nodePath = this.tenantFolderName + productPath;
    if (subFolder != null) {
      nodePath = nodePath + "/" + subFolder;
    }
    let productFileListPath = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/files?path=' + nodePath;
    return this.http.get<any>(productFileListPath);
  }

  public getEmployeeTags() {
    let requestUrl = this.apiBaseUrl + 'EmployeeTags';
    return this.http.get<string[]>(requestUrl);
  }

  public GetEmployeeMessages() {
    let requestUrl = this.apiBaseUrl + 'Messages';
    return this.http.get<EmployeeMessage[]>(requestUrl);
  }
  public GetEmployeeMessageById(id: string) {
    let requestUrl = this.apiBaseUrl + 'Messages/' + id;
    return this.http.get<EmployeeMessage>(requestUrl);
  }
  public GetEmployeeMessageCount() {
    let requestUrl = this.apiBaseUrl + 'Messages/Count';
    return this.http.get<EmployeeMessageCount>(requestUrl);
  }

  public PostEmployeeMessage(employeeMessage: EmployeeMessage) {
    let requestUrl = this.apiBaseUrl + 'Messages';
    let employeeData: EmployeeMessage[] = [];
    employeeData.push(employeeMessage);
    return this.http.post<any>(requestUrl, employeeData);
  }

  public IsNicInWorkTime(region: string) {
    let requestUrl = this.apiBaseUrl + 'nicinworktime' + '?region=' + region;
    return this.http.get<boolean>(requestUrl);
  }

  public UpdateLanguageSelection(langaugeSelectors: LanguageCulture) {
    langaugeSelectors.siteName = this.siteName;
    let requestUrl = this.apiBaseUrl + 'language/UpdateLanguageCulture';
    return this.http.post<boolean>(requestUrl, langaugeSelectors);
  }

  getCompactEmployeeInformation() {
    const employeeInfoUrl = this.apiBaseUrl + 'CompactEmployeeInformation';
    return this.http.post<CompactEmployeeInformationModel>(employeeInfoUrl, null);
  }

  /**
   * Perform Client Side Jackal Calculation
   */
  public runCalcsForAddOns(addOnGroupInformationType: Array<any>, globalDataDictionary: any, adjustmentFactor: number | null, eventData = null, skipCalc: boolean = false) {
    if (!skipCalc) {
      if (eventData) {
        eventData.overallCost_PayFrequencyTotal = 0
        eventData.overallCost_AnnualTotal = 0
      }

      // 1 Iteration => For adding the data to data dictionary (GDD)
      addOnGroupInformationType.forEach((addonGroup: any) => {
        // Iterating the Add Ons
        addonGroup.AddOns.forEach((addOn: any) => {
          // Create Short form AddOn for Cross AddOn usage
          let tmp = "AddOn_" + addOn.Code.replaceAll(" ", "_");
          globalDataDictionary[tmp] = addOn;
        })
      })

      // 2 Iteration => For calculating the valueCalcDefinition and EECostCalcDefinition
      addOnGroupInformationType.forEach((addonGroup: any) => {
        // Iterating the Add Ons
        addonGroup.AddOns.forEach((addOn: any) => {
          // Adding information to the global data dictionary
          globalDataDictionary.AddOn = addOn;
          globalDataDictionary.Rate = addOn.Rate;
          globalDataDictionary.RateGroup = addOn.RateGroup;

          if (addOn?.MinValCalcDefinition != null) {
            // Calling Jackal for doing the addon min value calc
            this._jackalEngineEvaluation(addOn.MinValCalcDefinition, globalDataDictionary);
          }

          if (addOn?.MaxValCalcDefinition != null) {
            // Calling Jackal for doing the addon max value calc
            this._jackalEngineEvaluation(addOn.MaxValCalcDefinition, globalDataDictionary);
          }

          if (addOn.OverrideMaximumAmount != null) {
            addOn.MaximumAmount = Math.min(addOn?.MaximumAmount, addOn?.OverrideMaximumAmount);
          }
          if (addOn.OverrideMinimumAmount != null) {
            addOn.MinimumAmount = Math.max(addOn?.MinimumAmount, addOn?.OverrideMinimumAmount);
          }
        
          addOn.ShowCoverageOptionCalc = true;
          globalDataDictionary.AddOn = addOn;
          if (addOn.HideCoverageOptionCalc) {
            // Calling Jackal for doing the Hide Coverage calc
            this._jackalEngineEvaluation(addOn.HideCoverageOptionCalc, globalDataDictionary, { name: "Addon.HideCoverageOptionCalc", isResultUsed: true });
            globalDataDictionary.AddOn = addOn;
            if (!globalDataDictionary?.Result) {
              addOn.ShowCoverageOptionCalc = false;
              addOn.EmployeeInputAmount = null;
              addOn.EmployeeValueListValue = null;
            }
          }

          if (addOn.ValueCalcDefinition != null) {
            // Calling Jackal for doing the value calc
            this._jackalEngineEvaluation(addOn.ValueCalcDefinition, globalDataDictionary, { name: "Addon.ValueCalcDefinition", isResultUsed: true });
            addOn.ValueCalcResult = globalDataDictionary.Result;
            if (!addOn.LookupBasicInputType_RecordID) {
              addOn.EmployeeInputAmount = addOn.ValueCalcResult;
              globalDataDictionary.AddOn = addOn;
            }
          }

          const EECostCalcDefinition = addOn.EECostCalcDefinition || addOn.EeCostCalcDefinition;
          if (EECostCalcDefinition != null) {
            // Calling Jackal for doing the EE cost calc
            this._jackalEngineEvaluation(EECostCalcDefinition, globalDataDictionary);

            // Calculating the Pay Frequency for EE
            addOn.EmployeeCost_PayFrequency = (addOn.Rate.Calculated_EmployeeCost_RateFrequency * addOn.RateGroup.Calculated_FrequencyDenominator) / globalDataDictionary?.Line?.Calculated_PayFrequencyDenominator;
          }

          const updateAdjustmentTypeCosts = () => {
            addOn.AdjustmentType.EmployeeCost = addOn.EmployeeCost_PayFrequency;
            // Apply Adjustment Factor
            if (adjustmentFactor != null) {
              addOn.AdjustmentType.EmployeeCost *= adjustmentFactor;
              // Rounding Off
              addOn.AdjustmentType.EmployeeCost = parseFloat(addOn.AdjustmentType.EmployeeCost?.toFixed(2));
            }
          }

          // Update adjustmentType cost
          if (addOn?.AdjustmentType != null) { updateAdjustmentTypeCosts() }

          // Releasing the added information from global Data dictionary
          delete globalDataDictionary.AddOn;
          delete globalDataDictionary.Rate;
          delete globalDataDictionary.RateGroup;

          // Calculating Annual Cost
          addOn.EmployeeCost_Annual = addOn.EmployeeCost_PayFrequency * globalDataDictionary?.Line?.Calculated_PayFrequencyDenominator;
        })
      })
    }

    // 3 Iteration => For calculating the ERCostCalcDefinition
    addOnGroupInformationType.forEach((addonGroup: any) => {
      // Iterating the Add Ons
      addonGroup.AddOns.forEach((addOn: any) => {
        if (!skipCalc) {
          if (addOn.ERCostCalcDefinition != null || addOn.ErCostCalcDefinition != null) {
            // Adding information to the global data dictionary
            globalDataDictionary.AddOn = addOn;
            globalDataDictionary.Rate = addOn.Rate;
            globalDataDictionary.RateGroup = addOn.RateGroup;

            // Calling Jackal for doing the ER cost calc
            this._jackalEngineEvaluation((addOn.ERCostCalcDefinition ? addOn.ERCostCalcDefinition : addOn.ErCostCalcDefinition), globalDataDictionary)

            // Calculating the Pay Frequency for ER
            addOn.EmployerCost_PayFrequency = (addOn.Rate.Calculated_EmployerCost_RateFrequency * addOn.RateGroup.Calculated_FrequencyDenominator) / globalDataDictionary?.Line?.Calculated_PayFrequencyDenominator;

            // Releasing the added information from global Data dictionary
            delete globalDataDictionary.AddOn;
            delete globalDataDictionary.Rate;
            delete globalDataDictionary.RateGroup;
          }

          const updateAdjustmentTypeCosts = () => {
            addOn.AdjustmentType.EmployerCost = addOn.EmployerCost_PayFrequency;
            // Apply Adjustment Factor
            if (adjustmentFactor != null) {
              addOn.AdjustmentType.EmployerCost *= adjustmentFactor;
              // Rounding Off
              addOn.AdjustmentType.EmployerCost = parseFloat(addOn.AdjustmentType.EmployerCost?.toFixed(2));
            }
          }

          // Update adjustmentType cost
          if (addOn?.AdjustmentType != null) { updateAdjustmentTypeCosts() }

          // Calculating Annual Cost
          addOn.EmployerCost_Annual = addOn.EmployerCost_PayFrequency * globalDataDictionary?.Line?.Calculated_PayFrequencyDenominator;
        }

        if (eventData) {
          eventData.overallCost_PayFrequencyTotal += addOn.EmployeeCost_PayFrequency + addOn.EmployerCost_PayFrequency;
          eventData.overallCost_AnnualTotal += addOn.EmployeeCost_Annual + addOn.EmployerCost_Annual;
        }
      })
    })
  }

  private _jackalEngineEvaluation(def, dict, options: {
    name: string,
    isResultUsed: boolean
  } = undefined) {
    try {
      if (options?.isResultUsed && dict?.Result) {
        dict.Result = undefined;
      }
      this.jackalEngine.Evaluate(def, dict);
      if (options?.isResultUsed) {
        if (dict?.Result == null || dict?.Result == undefined) {
          console.log("Calc Definition: " + options.name + " is missing a result field");
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public RunCalcsForCoverage(coverageInformationTypes: Array<any>, globalDataDictionary: any, adjustmentFactor: number | null) {
    coverageInformationTypes.forEach((coverageInformationType) => {
      globalDataDictionary.Coverage = coverageInformationType
      globalDataDictionary.Rate = coverageInformationType.Rate;
      globalDataDictionary.RateGroup = coverageInformationType.RateGroup;

      if (coverageInformationType.EECostCalcDefinition != null || coverageInformationType.EeCostCalcDefinition != null) {
        // Calling Jackal for doing the EE coverage calc
        this._jackalEngineEvaluation((coverageInformationType.EECostCalcDefinition ? coverageInformationType.EECostCalcDefinition : coverageInformationType.EeCostCalcDefinition), globalDataDictionary)

        // Calculating the Pay Frequency for EE
        coverageInformationType.EmployeeCost_PayPeriod = (coverageInformationType.Calculated_EmployeeCost_RateFrequency * coverageInformationType.RateGroup.Calculated_FrequencyDenominator) / globalDataDictionary.Line.Calculated_PayFrequencyDenominator
      }
      if (coverageInformationType.ERCostCalcDefinition != null || coverageInformationType.ErCostCalcDefinition != null) {
        // Calling Jackal for doing the ER coverage calc
        this._jackalEngineEvaluation(coverageInformationType.ERCostCalcDefinition ? coverageInformationType.ERCostCalcDefinition : coverageInformationType.ErCostCalcDefinition, globalDataDictionary)

        // Calculating the Pay Frequency for ER
        coverageInformationType.EmployerCost_PayPeriod = (coverageInformationType.Calculated_EmployerCost_PayFrequency * coverageInformationType.RateGroup.Calculated_FrequencyDenominator) / globalDataDictionary.Line.Calculated_PayFrequencyDenominator
      }

      const updateAdjustmentTypeCosts = () => {
        coverageInformationType.AdjustmentType.EmployeeCost = coverageInformationType.EmployeeCost_PayPeriod;
        coverageInformationType.AdjustmentType.EmployerCost = coverageInformationType.EmployerCost_PayPeriod;
        // Apply Adjustment Factor
        if (adjustmentFactor != null) {
          coverageInformationType.AdjustmentType.EmployeeCost *= adjustmentFactor;
          coverageInformationType.AdjustmentType.EmployerCost *= adjustmentFactor;
          // Rounding Off
          coverageInformationType.AdjustmentType.EmployeeCost = parseFloat(coverageInformationType.AdjustmentType.EmployeeCost?.toFixed(2));
          coverageInformationType.AdjustmentType.EmployerCost = parseFloat(coverageInformationType.AdjustmentType.EmployerCost?.toFixed(2));
        }
      }

      // Update adjustmentType costs
      if (coverageInformationType?.AdjustmentType != null) { updateAdjustmentTypeCosts() }

      delete globalDataDictionary.Coverage;
      delete globalDataDictionary.Rate;
      delete globalDataDictionary.RateGroup;

      //Calculating EE and ER Annual Cost
      coverageInformationType.EmployeeCost_Annual = coverageInformationType.EmployeeCost_PayPeriod * globalDataDictionary.Line.Calculated_PayFrequencyDenominator;
      coverageInformationType.EmployerCost_Annual = coverageInformationType.EmployerCost_PayPeriod * globalDataDictionary.Line.Calculated_PayFrequencyDenominator;

      //Totals
      coverageInformationType.TotalCost_PayPeriod = coverageInformationType.EmployeeCost_PayPeriod + coverageInformationType.EmployerCost_PayPeriod;
      coverageInformationType.TotalCost_Annual = coverageInformationType.EmployeeCost_Annual + coverageInformationType.EmployerCost_Annual;
    })
  }

  /**
   * Check the Cached Benefit Review Data and Clear if it moved out of the retention path
   */
  public checkAndRemoveTheCachedBenefitReviewData(): void {
    try {
      let cachedData: any = JSON.parse(window.atob(sessionStorage.getItem(SessionStorageKey.CachedBenefitReviewData)));
      if (!cachedData.retentionPath.includes(this.router.url) && cachedData.retentionPath.length) {
        sessionStorage.removeItem(SessionStorageKey.CachedBenefitReviewData);
      }
    } catch { }
  }
}

